import React, { Component, useEffect, useState } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import ReactApexChart from "react-apexcharts";
import Tendance from "../Tendance/Tendance.jsx";
import ReactToPrint from "react-to-print";
import GraphiquePulseur from "../GraphiquePulseur/GraphiquePulseur.jsx";
import { BsCircleFill } from "react-icons/bs";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import Select from "react-select";

class ResultatPulseur extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Vert", "Orange", "Rouge"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 80,
              },

              legend: {
                show: false, // Masquer la légende
              },
            },
          },
        ],
      },

      //Champs du formulaire
      idEquipe: " ",
      idCritere: [],
      result: [],
      resultN1: [],
      resultN2: [],
      commentaireRegroupes: {},
      afficheResult: false,
      action: [],
      modal: false,
      modalshow: false,

      //Erreur de champs
      loadingCritere: true,
      commentaire: true,
      status: "",
      description: "",
      niveau: "",
      descriptionError: "",
      statusError: "",
      niveauError: "",
      loadingActionCritere: true,
      idPriorite: "",
      idStatus: "",
      idNiveau: "",
      modalStatus: false,
      modalPriorite: false,
      modalNiveau: false,
    };
  }

  handleInputChangeNewPriorite = (event) => {
    this.setState({ newPriorite: event.target.value });
  };

  ValidateNewPriorite = () => {
    const newPrioriteValue = this.state.newPriorite.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newPriorite ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newPrioriteValue === "") {
      this.setState({ newPrioriteError: "Le champ ne doit pas être vide" });
      return false;
    } else if (!lettersRegex.test(newPrioriteValue)) {
      this.setState({
        newPrioriteError: "Le champ ne doit contenir que des lettres.",
      });
      return false;
    } else {
      this.setState({ newPrioriteError: "" });
      return true;
    }
  }

  RegisterPriorite = () => {
    if (this.ValidateNewPriorite()) {
    const body = {
      method: "POST",
      body: JSON.stringify({ newPriorite: this.state.newPriorite }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/action/registerPriorite", body).then((res) => {
      if (res.status === 201) {
        alert("la priorite a été creer avec succès !");
        this.setState({
          newPriorite: "",
          modalPriorite: false,
        });
        this.fetchPriorite();
      } else {
        alert("Erreur lors de la création de la priorite ...");
        this.setState({
          newPriorite: "",
          modalPriorite: false,
        });
        throw new Error(res.error);
      }
    });}
  };

  handleInputChangeNewNiveau = (event) => {
    this.setState({ newNiveau: event.target.value });
  };

  ValidateNewNiveau= () => {
    const newNiveauValue = this.state.newNiveau.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newNiveau ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newNiveauValue === "") {
      this.setState({ newNiveauError: "Le champ ne doit pas être vide" });
      return false;
    }else if (!lettersRegex.test(newNiveauValue)) {
      this.setState({
        newNiveauError: "Le champ ne doit contenir que des lettres.",
      });
      return false;
    }else{
      this.setState({ newNiveauError: "" });
      return true;
    }
  }

  RegisterNiveau = () => {

    if (this.ValidateNewNiveau()) {
    const body = {
      method: "POST",
      body: JSON.stringify({ newNiveau: this.state.newNiveau }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/action/registerNiveau", body).then((res) => {
      if (res.status === 201) {
        alert("le niveau a été creer avec succès !");
        this.setState({
          newNiveau: "",
          modalNiveau: false,
        });
        this.fetchNiveau();
      } else {
        alert("Erreur lors de la création du niveau ...");
        this.setState({
          newNiveau: "",
          modalNiveau: false,
        });
        throw new Error(res.error);
      }
    });
  }
  };

  ValidateNewStatut = () => {
    
    const newStatutValue = this.state.newStatut.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newStatut ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newStatutValue === "") {
      this.setState({ newStatutError: "Le champ ne doit pas être vide" });
      return false;
    }else if (!lettersRegex.test(newStatutValue)) {
      this.setState({
        newStatutError: "Le champ ne doit contenir que des lettres.",
      });
      return false;
    }else{
      this.setState({ newStatutError: "" });
      return true;
    }
  };

  handleInputChangeNewStatut = (event) => {
    this.setState({ newStatut: event.target.value });
  };
  RegisterStatut = () => {
    if (this.ValidateNewStatut()) {
      const body = {
        method: "POST",
        body: JSON.stringify({ newStatut: this.state.newStatut }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/action/registerStatut", body).then((res) => {
        if (res.status === 201) {
          alert("le statut a été creer avec succès !");
          this.setState({
            newStatut: "",
            modalStatus: false,
          });
          this.fetchStatus();
        } else {
          alert("Erreur lors de la création du statut ...");
          this.setState({
            newStatut: "",
            modalStatus: false,
          });
          throw new Error(res.error);
        }
      });
    }
  };

  fetchStatus = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllStatus", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ allStatus: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchNiveau = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllNiveau", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ allNiveau: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchPriorite = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllPriorite", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ allPriorite: data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  redirectPlanActionPage = (event) => {
    event.preventDefault();

    this.props.navigate(`/planAction/${this.state.idEquipe}`);
  };

  RegisterAction = (event) => {
    event.preventDefault();

    if (this.validate()) {
      const body = {
        idPriorite: this.state.idPriorite,
        idEquipe: this.state.idEquipe,
        idStatus: this.state.idStatus,
        description: this.state.description,
        idNiveau: this.state.idNiveau,
        idPulseur: this.props.params.id,
        idCritere: this.state.criteres[this.state.currentModalIndex].ID_CRITERE,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/action/", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            alert("l action a été creer avec succès !");
            this.setState({
              description: "",
              status: "",
              niveau: "",
              modal: false,
            });
          } else if (res.status === 449) {
            alert(`L'action  a déjà  été entrée ...`);
            this.setState({
              description: "",
              status: "",
              niveau: "",
              modal: false,
            });
          } else {
            alert("Erreur lors de la création de l'action ...");
            this.setState({
              description: "",
              status: "",
              niveau: "",
              modal: false,
            });
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //handleChange pour les autres champs

  handleChangePriorite = (event) => {
    this.setState({
      idPriorite: event.value,
    });
    this.setState({
      modif: true,
    });
  };
  handleChangeNiveau = (event) => {
    this.setState({
      idNiveau: event.value,
    });
    this.setState({
      modif: true,
    });
  };
  handleChangeStatus = (event) => {
    this.setState({
      idStatus: event.value,
    });
    this.setState({
      modif: true,
    });
  };
  handleOptionChangeDescription = (changeEvent) => {
    this.setState({ description: changeEvent.target.value });
  };

  validate = () => {
    let descriptionError = "";
    let statusError = "";
    let niveauError = "";
    let prioriteError = "";

    //Validation description
    if (!this.state.description) {
      descriptionError = "Entrer une description svp";
    }

    //Validation status
    if (!this.state.idStatus) {
      statusError = "un status doit être renseigné";
    }

    //Validation niveau
    if (!this.state.idNiveau) {
      niveauError = "un niveau doit être renseigné";
    }
    if (!this.state.idPriorite) {
      prioriteError = "une priorite doit être renseigné";
    }

    //Retour d'erreur
    if (descriptionError || statusError || niveauError) {
      this.setState({
        descriptionError,
        statusError,
        niveauError,
        prioriteError,
      });
      return false;
    } else {
      this.setState({
        descriptionError,
        statusError,
        niveauError,
        prioriteError,
      });
      return true;
    }
  };

  handleModalOpen = (index) => {
    this.setState({
      modal: true,
      currentModalIndex: index, // Ajoutez un nouvel état pour stocker l'index du modal actuel
    });
  };

  handleModalOpenShow = (index) => {
    this.setState({
      modalshow: true,
      currentModalShowIndex: index, // Ajoutez un nouvel état pour stocker l'index du modal actuel
    });
  };
  FetchActionByCritere = async (idCritere) => {
    const body = {
      idPulseur: this.props.params.id,
      idCritere: idCritere,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/action/getActionByCriterePulseur", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({ actionCritere: data, loadingActionCritere: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  calculateAndSortByColorPercentages(data) {
    // Créez un objet pour stocker les pourcentages de couleur par critère
    const criteriaColorPercentages = {};

    // Parcourez le tableau pour calculer les pourcentages de couleur par critère
    data.forEach((item) => {
      const { ID_CRITERE, COULEUR, LIBELLE } = item;

      if (!criteriaColorPercentages[ID_CRITERE]) {
        criteriaColorPercentages[ID_CRITERE] = {
          Vert: 0,
          Orange: 0,
          Rouge: 0,
          Total: 0,
          Libelle: LIBELLE, // Utilisez la propriété "LIBELLE" pour le libellé
        };
      }
      criteriaColorPercentages[ID_CRITERE].Total++;
      if (COULEUR === "Vert") {
        criteriaColorPercentages[ID_CRITERE].Vert++;
      } else if (COULEUR === "Orange") {
        criteriaColorPercentages[ID_CRITERE].Orange++;
      } else if (COULEUR === "Rouge") {
        criteriaColorPercentages[ID_CRITERE].Rouge++;
      }
    });

    // Créez un tableau pour stocker les résultats triés
    const sortedResults = [];

    // Parcourez l'objet des pourcentages calculés et créez un tableau de résultats triés
    for (const ID_CRITERE in criteriaColorPercentages) {
      const percentages = criteriaColorPercentages[ID_CRITERE];

      const total = percentages.Total;
      const vertPercentage = (percentages.Vert / total) * 100;
      const orangePercentage = (percentages.Orange / total) * 100;
      const rougePercentage = (percentages.Rouge / total) * 100;

      sortedResults.push({
        id: ID_CRITERE,
        libelle: percentages.Libelle,
        pourcentageVert: vertPercentage.toFixed(1) + "%",
        pourcentageOrange: orangePercentage.toFixed(1) + "%",
        pourcentageRouge: rougePercentage.toFixed(1) + "%",
      });
    }

    // Triez le tableau de résultats en fonction du pourcentage de Rouge et Orange (le plus élevé d'abord)
    // sortedResults.sort((a, b) => {
    //   if (a.pourcentageRouge !== b.pourcentageRouge) {
    //     return parseFloat(b.pourcentageRouge) - parseFloat(a.pourcentageRouge);
    //   }

    //   if (a.pourcentageOrange !== b.pourcentageOrange) {
    //     return (
    //       parseFloat(b.pourcentageOrange) - parseFloat(a.pourcentageOrange)
    //     );
    //   }
    //   if (a.pourcentageVert !== b.pourcentageVert) {
    //     return parseFloat(b.pourcentageVert) - parseFloat(a.pourcentageVert);
    //   }
    //   return parseFloat(b.pourcentageVert) - parseFloat(a.pourcentageVert);
    // });
    sortedResults.sort((a, b) => {
      const percentageAVert = parseFloat(a.pourcentageVert);
      const percentageBVert = parseFloat(b.pourcentageVert);

      if (percentageAVert !== percentageBVert) {
        return percentageAVert - percentageBVert;
      }
      // Si le pourcentage de Vert est le même, triez en fonction du pourcentage de Rouge décroissant
      return parseFloat(b.pourcentageRouge) - parseFloat(a.pourcentageRouge);
    });

    this.setState({
      result: sortedResults,
    });

    return sortedResults;
  }

  groupCommentsByCriteria(apiResponse) {
    const commentairesParCritere = {};

    apiResponse.forEach((item) => {
      const critereId = item.ID_CRITERE;
      const commentaire = item.COMMENTAIRE;

      if (!commentairesParCritere[critereId]) {
        commentairesParCritere[critereId] = [];
      }

      commentairesParCritere[critereId].push(commentaire);
    });

    return commentairesParCritere;
  }

  // fonction qui mlange les tableaux
  shuffleArray(array) {
    // Créez une copie du tableau pour ne pas le modifier directement
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Générez un index aléatoire
      const j = Math.floor(Math.random() * (i + 1));

      // Échangez les éléments aux indices i et j
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }

  // fonction qui calule les resultat pour le graphique
  calculateGreenPercentage = (results, critereId) => {
    const totalResponses = results.filter(
      (result) => result.ID_CRITERE === critereId
    ).length;
    const greenResponses = results.filter(
      (result) => result.ID_CRITERE === critereId && result.COULEUR === "Vert"
    ).length;
    return (greenResponses / totalResponses) * 100;
  };

  // fonction qui calule la tendance
  calculatePercentageExcludingImprovementForCriteria = (results, critereId) => {
    // Filtrer les réponses par critère spécifié (critereId)
    const filteredResponses = results.filter(
      (response) => response.ID_CRITERE === critereId
    );

    // Compter le nombre total de réponses pour chaque type
    const improvementCount = filteredResponses.filter(
      (response) => response.TENDANCE === "amelioration"
    ).length;

    const stableCount = filteredResponses.filter(
      (response) => response.TENDANCE === "stable"
    ).length;

    const degradationCount = filteredResponses.filter(
      (response) => response.TENDANCE === "degradation"
    ).length;

    // Calculer le nombre total de réponses
    const totalResponses = filteredResponses.length;

    // Calculer le poids total en fonction des réponses
    const totalWeight =
      improvementCount * 1 + stableCount * 0.5 + degradationCount * 0;

    // Calculer le pourcentage général en fonction du poids total
    const percentageGeneral = (totalWeight / totalResponses) * 100;

    return percentageGeneral.toFixed(1);
  };

  //Fonction au chargement de la page pour récupérer les informations d'affichage
  async componentDidMount() {
    this.fetchPriorite();
    this.fetchNiveau();
    this.fetchStatus();
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    await fetch("/api/isAdmin", requestOptions).then((res) => {
      if (res.status === 200 || res.status === 304) {
        this.setState({ isAdmin: true, loading: false });
      } else {
        this.setState({ isAdmin: false, loading: false });
      }
    });
    //Récupération de id Equipe

    const bodyIdPulseur = {
      idPulseur: this.props.params.id,
    };

    const get = {
      method: "POST",
      body: JSON.stringify(bodyIdPulseur),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/criteres/getCritereByPulseur", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          this.state.idCritere.push(data[i].ID_CRITERE);
        }
        this.setState({
          criteres: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    await fetch("/api/equipes/getEquipeByPulseur", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          idEquipe: data.ID_EQUIPE,
          libelle_equipe: data.LIBELLE_EQUIPE,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const body = {
      idPulseur: this.props.params.id,
      idEquipe: this.state.idEquipe,
    };

    const getResultatByPulseur = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/pulseur/getResultatByPulseur", getResultatByPulseur)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ Acces: true });
        }

        return res.json();
      })
      .then((data) => {
        this.setState({
          resultat: data.resultat,
          resultatN1: data.resultatN1,
          resultatN2: data.resultatN2,
        });

        const commentairesRegroupes = this.groupCommentsByCriteria(
          data.resultat
        );
        this.setState({
          commentaireRegroupes: commentairesRegroupes,
        });

        this.setState({
          loadingCritere: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    this.calculateAndSortByColorPercentages(this.state.resultat);
  }

  //Fonction de rendu

  render() {
    if (this.state.Acces === true) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          Unauthorized
        </h1>
      );
    } else if (this.state.loadingCritere) {
      return (
        <h1 className="flex justify-center items-center h-screen text-2xl">
          {" "}
          loading
        </h1>
      );
    } else if (this.state.isAdmin) {
      return (
        <>
          <div ref={this.componentRef}>
            <h1 className="text-center text-orange-incubate text-lg mt-5">
              Resultat Pulseur :{" "}
              <span className="ml-3 text-orange-incubate underline">
                {this.state.libelle_equipe}
              </span>
            </h1>
            <div className="flex justify-center">
              <button
                className="bg-bleu-incubate text-white rounded-lg shadow-lg p-2 mt-5 mb-5 ml-2"
                onClick={() => {
                  if (this.state.afficheResult === true) {
                    this.setState({ afficheResult: false });
                  } else {
                    this.setState({ afficheResult: true });
                  }
                }}>
                Tableau récapilulatif
              </button>
              <button className="p-3 " onClick={this.redirectPlanActionPage}>
                <MdOutlineLibraryBooks size={50} color="green" />
              </button>
            </div>

            {/* table */}

            {this.state.afficheResult === true ? (
              <>
                
                <div className="flex justify-center mt-5 ">
                  <table className="shadow-lg bg-white rounded-lg">
                    <thead>
                      <tr className="text-center border border-black ">
                        <th className="text-sm  text-bleu-incubate bg-blue-50 border border-black">
                          Critères
                        </th>

                        <th className="text-sm text-bleu-incubate bg-green-100 border border-black">
                          % Vert
                        </th>
                        <th className="text-sm text-bleu-incubate bg-orange-100 border border-black">
                          % Orange
                        </th>
                        <th className="text-sm text-orange-incubate bg-red-100 border border-black">
                          % Rouge
                        </th>
                      </tr>
                    </thead>
                    {this.state.result.map((element, index) => (
                      <tbody key={index}>
                        <td className=" text-orange-incubate p-2 text-sm border border-black">
                          {element.libelle}
                        </td>
                        <td className=" bg-green-50 p-2 text-sm border border-black">
                          {element.pourcentageVert}
                        </td>
                        <td className=" bg-orange-50 p-2 text-sm border border-black">
                          {element.pourcentageOrange}
                        </td>
                        <td className=" bg-red-50 p-2 text-sm border border-black">
                          {element.pourcentageRouge}
                        </td>
                      </tbody>
                    ))}
                  </table>
                </div>
              </>
            ) : null}

            <div className="flex justify-center  items-center mt-5 p-4  rounded-lg  w-3/4 mx-auto">
              <table>
                <thead></thead>
                <tbody>
                  {this.state.idCritere.map((element, index) => (
                    <React.Fragment key={index}>
                      <tr className="text-center ">
                        <th className="text-xl  text-bleu-incubate">Critère</th>

                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-2
                        </th>
                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-1
                        </th>
                        <th className="text-xl text-orange-incubate">
                          Pulseur
                        </th>

                        <th className="text-xl text-orange-incubate">
                          Tendance
                        </th>
                        <th className="text-xl text-orange-incubate">
                          {" "}
                          Evolution
                        </th>
                      </tr>
                      <tr className=" rounded-xl   shadow-lg mt-2" key={index}>
                        {/* criteres  */}
                        <td className=" text-orange-incubate p-5 text-xl">
                          {index + 1}
                          {")"} {this.state.criteres[index].LIBELLE}
                          <div className="flex items-center space-x-2 mt-2">
                            <div className="">
                              <BsCircleFill color="green" />
                            </div>
                            <p className="text-sm text-green-800 flex">
                              {" "}
                              {this.state.criteres[index].LIBELLE_VERT}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2 mt-2">
                            <div>
                              <BsCircleFill color="red" />{" "}
                            </div>

                            <p className="text-sm text-red-800">
                              {this.state.criteres[index].LIBELLE_ROUGE}
                            </p>
                          </div>
                        </td>

                        {/* resultat N-2 */}

                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN2,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N-1 */}
                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN1,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N */}
                        <td>
                          <div className="flex items-center p-4 justify-center shadow-2xl bg-gray-50 rounded-lg border border-orange-incubate">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultat,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* tendance */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <Tendance
                              resultat={this.calculatePercentageExcludingImprovementForCriteria(
                                this.state.resultat,
                                element
                              )}
                            />
                          </div>
                        </td>
                        {/* graphique */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <GraphiquePulseur
                              greenPercentageN={this.calculateGreenPercentage(
                                this.state.resultat,
                                element
                              ).toFixed(1)}
                              greenPercentageN1={this.calculateGreenPercentage(
                                this.state.resultatN1,
                                element
                              ).toFixed(1)}
                              greenPercentageN2={this.calculateGreenPercentage(
                                this.state.resultatN2,
                                element
                              ).toFixed(1)}
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            className="p-3 "
                            onClick={() => {
                              this.handleModalOpen(index);
                            }}>
                            <MdOutlineBookmarkAdd size={30} color="green" />
                          </button>

                          <button
                            className="p-3 "
                            onClick={() => {
                              this.handleModalOpenShow(index);

                              this.FetchActionByCritere(
                                this.state.criteres[index].ID_CRITERE
                              );
                            }}>
                            <FaEye size={30} color="black" />
                          </button>
                        </td>
                      </tr>
                      {/* MODAL REGISTER*/}
                      {this.state.modal ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                            <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-orange-incubate">
                                    Action{" "}
                                    {
                                      this.state.criteres[
                                        this.state.currentModalIndex
                                      ].LIBELLE
                                    }
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() =>
                                      this.setState({ modal: false })
                                    }>
                                    <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                  <form className="">
                                    <div className="md:mb-4">
                                      <label
                                        htmlFor="description"
                                        className="block font-semibold">
                                        Description
                                      </label>
                                      <textarea
                                        onChange={
                                          this.handleOptionChangeDescription
                                        }
                                        value={this.state.description}
                                        rows="4"
                                        className="w-full shadow appearance-none border rounded-lg text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"
                                        placeholder="...."
                                        required></textarea>
                                      {this.state.descriptionError ? (
                                        <div className="text-red-600">
                                          {this.state.descriptionError}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="w-11/12 mb-3 flex space-x-6 items-center ">
                                      <div className="w-full">
                                        <Select
                                          aria-labelledby="Statut-label"
                                          inputId="Statut-input"
                                          name="Statut"
                                          onMenuOpen={this.onMenuOpen}
                                          onMenuClose={this.onMenuClose}
                                          placeholder="Statut"
                                          options={this.state.allStatus.map(
                                            (status) => ({
                                              value: status.ID_STATUS,
                                              label: status.LIBELLE,
                                            })
                                          )}
                                          onChange={this.handleChangeStatus}
                                        />
                                        {this.state.statusError ? (
                                          <div className="text-red-600">
                                            {this.state.statusError}
                                          </div>
                                        ) : null}
                                      </div>

                                      <button
                                        onClick={() => {
                                          this.setState({
                                            modalStatus: true,
                                            modal: false,
                                          });
                                        }}>
                                        <MdAddCircle size={20} color="orange" />
                                      </button>
                                    </div>

                                    <div className="w-11/12  mb-3 flex space-x-6 items-center">
                                      <div className="w-full">
                                        {" "}
                                        <Select
                                          aria-labelledby="Niveau-label"
                                          inputId="Niveau-input"
                                          name="Niveau"
                                          onMenuOpen={this.onMenuOpen}
                                          onMenuClose={this.onMenuClose}
                                          placeholder="Niveau"
                                          options={this.state.allNiveau.map(
                                            (status) => ({
                                              value: status.ID_NIVEAU,
                                              label: status.LIBELLE,
                                            })
                                          )}
                                          onChange={this.handleChangeNiveau}
                                        />
                                        {this.state.niveauError ? (
                                          <div className="text-red-600">
                                            {this.state.niveauError}
                                          </div>
                                        ) : null}
                                      </div>

                                      <button
                                        onClick={() => {
                                          this.setState({
                                            modalNiveau: true,
                                            modal: false,
                                          });
                                        }}>
                                        <MdAddCircle size={20} color="orange" />
                                      </button>
                                    </div>

                                    <div className="w-11/12 mb-3 flex space-x-6 items-center">
                                      <div className="w-full">
                                        <Select
                                          aria-labelledby="Priorite-label"
                                          inputId="Priorite-input"
                                          name="Priorite"
                                          onMenuOpen={this.onMenuOpen}
                                          onMenuClose={this.onMenuClose}
                                          placeholder="Priorite"
                                          options={this.state.allPriorite.map(
                                            (status) => ({
                                              value: status.ID_PRIORITE,
                                              label: status.LIBELLE,
                                            })
                                          )}
                                          onChange={this.handleChangePriorite}
                                        />
                                        {this.state.niveauError ? (
                                          <div className="text-red-600">
                                            {this.state.prioriteError}
                                          </div>
                                        ) : null}
                                      </div>

                                      <button
                                        onClick={() => {
                                          this.setState({
                                            modalPriorite: true,
                                            modal: false,
                                          });
                                        }}>
                                        <MdAddCircle size={20} color="orange" />
                                      </button>
                                    </div>
                                  </form>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() =>
                                      this.setState({ modal: false })
                                    }>
                                    Fermer
                                  </button>
                                  <button
                                    className="bg-green-800 text-white active:bg-green-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.RegisterAction}>
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {/* MODAL show*/}

                      {this.state.modalshow ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                            <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-orange-incubate">
                                    Action{" "}
                                    {
                                      this.state.criteres[
                                        this.state.currentModalShowIndex
                                      ].LIBELLE
                                    }
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() =>
                                      this.setState({ modalshow: false })
                                    }>
                                    <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                  {this.state.loadingActionCritere === false &&
                                  this.state.actionCritere.length > 0 ? (
                                    <>
                                      <table className="shadow-lg bg-white rounded-lg w-full border border-black">
                                        <thead>
                                          <tr className="text-center border border-black">
                                            <th className="text-sm  text-bleu-incubate bg-blue-50 border border-black">
                                              Date
                                            </th>

                                            <th className="text-sm text-bleu-incubate bg-blue-50 border border-black">
                                              Description
                                            </th>
                                            <th className="text-sm text-bleu-incubate bg-blue-50 border border-black">
                                              Status
                                            </th>
                                            <th className="text-sm text-orange-incubate bg-blue-50 border border-black">
                                              Niveau
                                            </th>
                                            <th className="text-sm text-orange-incubate bg-blue-50 border border-black">
                                              Priorite
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {this.state.actionCritere.map(
                                            (action) => {
                                              return (
                                                <tr className="border border-black">
                                                  <td className=" text-orange-incubate p-2 text-sm border border-black ">
                                                    {action.DATE}
                                                  </td>
                                                  <td className=" bg-green-50 p-2 text-sm border border-black">
                                                    {action.DESCRIPTION}
                                                  </td>
                                                  <td className=" bg-orange-50 p-2 text-sm border border-black">
                                                    {action.LIBELLE_STATUS}
                                                  </td>
                                                  <td className=" bg-red-50 p-2 text-sm border border-black">
                                                    {action.LIBELLE_NIVEAU}
                                                  </td>
                                                  <td className=" bg-yellow-50 p-2 text-sm border border-black">
                                                    {action.LIBELLE_PRIORITE}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </>
                                  ) : (
                                    <p>Pas d'action definie</p>
                                  )}
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                      this.setState({ modalshow: false });
                                    }}>
                                    Fermer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {/* MODAL Status*/}

                      {this.state.modalStatus ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                            <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-orange-incubate">
                                    Ajouter un statut
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() =>
                                      this.setState({ modalStatus: false })
                                    }>
                                    <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                  <input
                                    type="text"
                                    name="newStatut"
                                    placeholder="Ajouter un statut"
                                    value={this.state.newStatut}
                                    onChange={this.handleInputChangeNewStatut}
                                    className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                                  />
                                  {this.state.newStatutError ? (
                                    <div className="text-red-600">
                                      {this.state.newStatutError}
                                    </div>
                                  ) : null}
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                      this.setState({ modalStatus: false });
                                    }}>
                                    Fermer
                                  </button>
                                  <button
                                    className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.RegisterStatut}>
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {/* MODAL Niveau*/}

                      {this.state.modalNiveau ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                            <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-orange-incubate">
                                    Ajouter un Niveau
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() =>
                                      this.setState({ modalNiveau: false })
                                    }>
                                    <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                  <input
                                    type="text"
                                    name="newNiveau"
                                    placeholder="Ajouter un niveau"
                                    value={this.state.newNiveau}
                                    onChange={this.handleInputChangeNewNiveau}
                                    className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                                  />
                                  {this.state.newNiveauError ? (
                                    <div className="text-red-600">
                                      {this.state.newNiveauError}
                                    </div>
                                  ) : null}
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                      this.setState({ modalNiveau: false });
                                    }}>
                                    Fermer
                                  </button>
                                  <button
                                    className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.RegisterNiveau}>
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {/* MODAL Priorite*/}

                      {this.state.modalPriorite ? (
                        <>
                          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                            <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-orange-incubate">
                                    Ajouter une priorité{" "}
                                  </h3>
                                  <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() =>
                                      this.setState({ modalPriorite: false })
                                    }>
                                    <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                  <input
                                    type="text"
                                    name="newPriorite"
                                    placeholder="Ajouter une priorite"
                                    value={this.state.newPriorite}
                                    onChange={this.handleInputChangeNewPriorite}
                                    className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                                  />
                                  {this.state.newPrioriteError ? (
                                    <div className="text-red-600">
                                      {this.state.newPrioriteError}
                                    </div>
                                  ) : null}
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                  <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {
                                      this.setState({ modalPriorite: false });
                                    }}>
                                    Fermer
                                  </button>
                                  <button
                                    className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.RegisterPriorite}>
                                    
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      {/* Commentaires */}
                      <tr>
                        <td colSpan="6">
                          {this.state.commentaireRegroupes[element].length &&
                          Object.keys(this.state.commentaireRegroupes[element])
                            .length > 0 ? (
                            <>
                              <h2 className="text-center underline mt-2 ">
                                Commentaires associés à ce critère
                              </h2>
                              <div className="grid grid-cols-5 gap-2 justify-center mt-1 mb-2">
                                {this.shuffleArray(
                                  Object.values(
                                    this.state.commentaireRegroupes[element]
                                  )
                                ).map((commentaire, commentaireIndex) => (
                                  <div className="shadow-md bg-sky-50 rounded-sm  p-2 w-52 h-auto">
                                    <p
                                      className="whitespace-normal break-words"
                                      key={commentaireIndex}>
                                      {commentaire}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <p>Pas de commentaires pour ce critère</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">
                          <hr className="bg-gray-900 mx-auto w-96 mb-5" />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {}
          <div className="flex justify-center rounded-lg shadow-lg border mx-auto mt-3 mb-3 text-orange-incubate border-orange-incubate hover:bg-orange-incubate hover:text-white w-32 p-2">
            <ReactToPrint
              trigger={() => <button>Exporter</button>}
              content={() => this.componentRef.current}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <h1 className="text-center text-orange-incubate text-lg mt-5">
              Resultat Pulseur :{" "}
              <span className="ml-3 text-orange-incubate underline">
                {this.state.libelle_equipe}
              </span>
            </h1>
            <div className="flex justify-center">
              <button
                className="bg-bleu-incubate text-white rounded-lg shadow-lg p-2 mt-5 mb-5 ml-2"
                onClick={() => {
                  if (this.state.afficheResult === true) {
                    this.setState({ afficheResult: false });
                  } else {
                    this.setState({ afficheResult: true });
                  }
                }}>
                Tableau récapilulatif
              </button>
            </div>

            {/* table */}

            {this.state.afficheResult === true ? (
              <>
                
                <div className="flex justify-center mt-5 ">
                  <table className="shadow-lg bg-white rounded-lg">
                    <thead>
                      <tr className="text-center border border-black ">
                        <th className="text-sm  text-bleu-incubate bg-blue-50 border border-black">
                          Critères
                        </th>

                        <th className="text-sm text-bleu-incubate bg-green-100 border border-black">
                          % Vert
                        </th>
                        <th className="text-sm text-bleu-incubate bg-orange-100 border border-black">
                          % Orange
                        </th>
                        <th className="text-sm text-orange-incubate bg-red-100 border border-black">
                          % Rouge
                        </th>
                      </tr>
                    </thead>
                    {this.state.result.map((element, index) => (
                      <tbody key={index}>
                        <td className=" text-orange-incubate p-2 text-sm border border-black">
                          {element.libelle}
                        </td>
                        <td className=" bg-green-50 p-2 text-sm border border-black">
                          {element.pourcentageVert}
                        </td>
                        <td className=" bg-orange-50 p-2 text-sm border border-black">
                          {element.pourcentageOrange}
                        </td>
                        <td className=" bg-red-50 p-2 text-sm border border-black">
                          {element.pourcentageRouge}
                        </td>
                      </tbody>
                    ))}
                  </table>
                </div>
              </>
            ) : null}

            <div className="flex justify-center  items-center mt-5 p-4  rounded-lg  w-3/4 mx-auto">
              <table>
                <thead></thead>
                <tbody>
                  {this.state.idCritere.map((element, index) => (
                    <React.Fragment key={index}>
                      <tr className="text-center ">
                        <th className="text-xl  text-bleu-incubate">Critère</th>

                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-2
                        </th>
                        <th className="text-xl text-bleu-incubate">
                          Pulseur N-1
                        </th>
                        <th className="text-xl text-orange-incubate">
                          Pulseur
                        </th>

                        <th className="text-xl text-orange-incubate">
                          Tendance
                        </th>
                        <th className="text-xl text-orange-incubate">
                          {" "}
                          Evolution
                        </th>
                      </tr>
                      <tr className=" rounded-xl   shadow-lg mt-2" key={index}>
                        {/* criteres  */}
                        <td className="text-md text-orange-incubate p-5 ">
                          {index + 1}
                          {")"} {this.state.criteres[index].LIBELLE}
                          <div className="flex items-center space-x-2 mt-2">
                            <div className="">
                              <BsCircleFill color="green" />
                            </div>
                            <p className="text-sm text-green-800 flex">
                              {" "}
                              {this.state.criteres[index].LIBELLE_VERT}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2 mt-2">
                            <div>
                              <BsCircleFill color="red" />{" "}
                            </div>

                            <p className="text-sm text-red-800">
                              {this.state.criteres[index].LIBELLE_ROUGE}
                            </p>
                          </div>
                        </td>

                        {/* resultat N-2 */}

                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN2,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N-1 */}
                        <td>
                          <div className="flex items-center p-4 justify-center">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultatN1,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* resultat N */}
                        <td>
                          <div className="flex items-center p-4 justify-center shadow-2xl bg-gray-50 rounded-lg border border-orange-incubate">
                            <Pie
                              element={{
                                element: element,
                                resultat: this.state.resultat,
                                index: index,
                              }}
                            />
                          </div>
                        </td>
                        {/* tendance */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <Tendance
                              resultat={this.calculatePercentageExcludingImprovementForCriteria(
                                this.state.resultat,
                                element
                              )}
                            />
                          </div>
                        </td>
                        {/* graphique */}
                        <td>
                          <div className="flex items-center p-4 justify-center  ">
                            <GraphiquePulseur
                              greenPercentageN={this.calculateGreenPercentage(
                                this.state.resultat,
                                element
                              ).toFixed(1)}
                              greenPercentageN1={this.calculateGreenPercentage(
                                this.state.resultatN1,
                                element
                              ).toFixed(1)}
                              greenPercentageN2={this.calculateGreenPercentage(
                                this.state.resultatN2,
                                element
                              ).toFixed(1)}
                            />
                          </div>
                        </td>
                      </tr>
                      {/* Commentaire */}
                      <tr>
                        <td colSpan="6">
                          {this.state.commentaireRegroupes[element].length &&
                          Object.keys(this.state.commentaireRegroupes[element])
                            .length > 0 ? (
                            <>
                              <h2 className="text-center underline mt-2 ">
                                Commentaires associés à ce critère
                              </h2>
                              <div className="grid grid-cols-5 gap-2 justify-center mt-1 mb-2">
                                {this.shuffleArray(
                                  Object.values(
                                    this.state.commentaireRegroupes[element]
                                  )
                                ).map((commentaire, commentaireIndex) => (
                                  <div className="shadow-md bg-sky-50 rounded-sm  p-2 w-52 h-auto">
                                    <p
                                      className="whitespace-normal"
                                      key={commentaireIndex}>
                                      {commentaire}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <p>Pas de commentaires pour ce critère</p>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    }
  }
}

function Pie(element) {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  let vert = 0;
  let orange = 0;
  let rouge = 0;

  for (let i = 0; i < element.element.resultat.length; i++) {
    if (element.element.resultat[i].ID_CRITERE === element.element.element) {
      if (element.element.resultat[i].COULEUR === "Vert") {
        vert = vert + 1;
      } else if (element.element.resultat[i].COULEUR === "Orange") {
        orange = orange + 1;
      } else if (element.element.resultat[i].COULEUR === "Rouge") {
        rouge = rouge + 1;
      }
    }
  }

  useEffect(() => {
    setSeries([vert, orange, rouge]);
    setLoading(false);
  }, []);

  const [options] = useState({
    colors: ["#01D758", "#ED7F10", "#ED0000"],
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Vert", "Orange", "Rouge"],
    legend: {
      show: false, // Masquer la légende
    },
    dataLabels: {
      style: {
        colors: ["#000"], // Couleur du texte en noir
      },
    },
  });

  if (loading) {
    return <h1> Loading...</h1>;
  } else {
    return (
      <div className="flex  w-full basis-1/4 justify-center">
        {series[0] === 0 && series[1] === 0 && series[2] === 0 ? (
          <div className="">
            {" "}
            <p className="text-md flex items-center text-center">
              Aucun contenu disponible.
            </p>{" "}
          </div>
        ) : (
          <>
            <ReactApexChart
              className=" flex justify-center items-center "
              options={options}
              series={series}
              type="pie"
              width={180}
            />
          </>
        )}
      </div>
    );
  }
}

//Export
export default withRouter(ResultatPulseur);
