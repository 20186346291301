import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import Select from 'react-select';

class CreationSocietes extends Component {
    constructor(props){
        super(props);
        this.state = {
            listeVisu : [{value : 0, label : 'Non'}, {value : 1, label:'Oui'}],

            //Champs du formulaire
            libelle : '',
            visu : 0,

            //Erreur de champs
            libelleError : '',
        }
    }

    //detecte un changement
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
    }

    //Dertecte un changement du chanmp visu
    handleChangeVisu = (event) => {
        this.setState({
          visu : event.value,
        });
    }

    //Fontion de validation
    validate = () => {
        let libelleError = '';

        //Validation prenom
        if(!this.state.libelle){
            libelleError = "Le libelle de la société doit être renseigné"
        }
        if(this.state.libelle.length > 45){
            libelleError = "Le libelle de la société est trop long"
        }


        //Retour d'erreur
        if(libelleError ){
            this.setState({libelleError});
            return false;   
        }else{
            this.setState({libelleError});
            return true; 
        }
    }

    //Fonction appalant l'api pour réaliser la création
    onClick = (event) => {
        event.preventDefault();

        if(this.validate()){
            const body = {
                libelleSociete : this.state.libelle,
                visuSociete : this.state.visu
            };
    
            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/societes/', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        alert('Société créée avec succès !')
                        this.props.navigate('/societes');
                    }else if(res.status === 449){
                        alert(`La société ${this.state.libelle} est déjà existante ...`);
                    }else {
                        alert("Erreur lors de la création de la société ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }


    //Fonction de rendu
    render(){
        return (
            <div className='flex flex-col items-center justify-center h-full pt-32'>
                <h1 className="text-orange-incubate text-3xl">Création d'une société</h1>
                <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                    <div className="md:mb-4">
                        <label htmlFor='libelle' className="block font-semibold">Libelle</label>
                        <input type='text' name='libelle' placeholder='Libelle' value={this.state.libelle} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                        {this.state.libelleError ? <div className="text-red-600">{this.state.libelleError}</div> : null }
                    </div>
                    <div className="md:mb-4">
                        <label htmlFor='visu' className="block font-semibold">Les membres de la société peuvent-ils voir les resultats de toute la société ?</label>
                            {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (<blockquote>"{this.state.ariaFocusMessage}"</blockquote>)}
                            <Select
                                aria-labelledby="Visu-label"
                                inputId="Visu-input"
                                name="Visu"
                                onMenuOpen={this.onMenuOpen}
                                onMenuClose={this.onMenuClose}
                                options={this.state.listeVisu}
                                onChange={this.handleChangeVisu}
                                defaultValue={this.state.listeVisu[this.state.visu]}
                            />                      
                    </div>
                    <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                        <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={()=>{this.props.navigate("/societes")}}>Retour</button>
                        <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.onClick}>Créer la société</button>
                    </div>
                </form>
            </div>
        );
        
    }
}

//Export
export default withRouter(CreationSocietes);