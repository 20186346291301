import React, { Component } from 'react'
import { withRouter } from '../Outils/withRouter';
import { BiRightArrow } from 'react-icons/bi';

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {};
    
  }

  //Fonction de redirection vers la page de connexion
  redirectLoginPage = (event) => {
    event.preventDefault();
    this.props.navigate('/login');
  }

  //Fonction de rendu
  render(){
    return (
      <div className='h-screen '>
        <div className='flex flex-row h-2/3 justify-center items-end'>
        <div className=' h-2/3 lg:w-1/2  flex flex-col justify-end items-center '>
          <div className='flex flex-col items-center '>
            <div className='flex flex-col text-xl lg:text-4xl '>
              <h1 className='flex justify-center'>MESUREZ LE RESSENTI DES ÉQUIPES</h1>
              <h1 className='flex justify-center'>AVEC PULSEUR</h1>
            </div>

            <div className='flex flex-col border-l-2 px-2 m-10 '>
              <h2>Nous vous accompagnons lors d'atelier collectif</h2>
              <h2>afin de mesurer le ressenti des équipes</h2>
              <h2>et de mettre en place des actions d'amélioration continue</h2>
            </div>
          </div>
          
          <div className='flex items-center justify-center pt-5'>
            <button type='button' onClick={this.redirectLoginPage} className="text-white py-2 px-5 mx-2 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate"><div className="flex flex-row items-center"><p className='px-2 '>Connexion</p><BiRightArrow  className='pt-1'/></div></button>
          </div>  
        </div>
        <div className='lg:flex hidden w-1/2 h-3/4'>
          <div className='flex justify-center items-center mt-20 ' >
            <img src='/images/page_home.png' alt='Logo Be Shake' className='h-10/12 '/>
          </div>
        </div>
        </div>
        <div className='h-1/3 flex justify-center items-end  ' >
          <img src='/images/IC_logo_couleurs_orange_nom_cote.png' alt='Logo Be Shake' className=' h-32'/>
        </div>
      </div>
      
    )
  }
  
}

//exports
export default withRouter(Home);