import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import { FaArrowUp, FaArrowRight, FaArrowDown } from "react-icons/fa";

const ResultatPulseurIndividu = memo(() => {
  const params = useParams();
  const idPulseur = params.id_pulseur;
  const id_pulseur_individu = params.id_pulseur_individu;
  const [resultat_individu, setResultat_individu] = useState([]);

  
  const FetchResultatByIndividu = async () => {
    const body = {
      idPulseur: idPulseur,
      id_pulseur_individu: id_pulseur_individu,
    };
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    await fetch("/api/pulseur/getResultatByPulseurByIndividu", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setResultat_individu(data);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Resultat en fonction du pulseur et de l'individu

  useEffect(() => {
    FetchResultatByIndividu();
  }, []);

  return (
    <>
      <h1 className="text-center text-orange-incubate text-xl mt-1">
        Mes responses
      </h1>
      <div className=" flex justify-center items-center mt-3">
        <table className="shadow-xl rounded-xl  ">
          <thead></thead>
          <tbody className=" ">
            {resultat_individu.map((resultat, index) => {
              return (
                <>
                  <tr className="text-center">
                    <th className="text-md text-left  text-bleu-incubate p-5 shadow-sm bg-slate-50">
                      Critère
                    </th>
                    <th className="text-md text-orange-incubate p-5 bg-green-50 shadow-sm">
                      Réponse
                    </th>
                    <th className="text-md text-orange-incubate p-5 bg-green-50 shadow-sm">
                      Tendance
                    </th>
                    <th className="text-md text-orange-incubate p-5 bg-green-50 shadow-sm">
                      Commentaire
                    </th>
                  </tr>
                  <tr
                    key={index}
                    className="items-center shadow-sm rounded-lg ">
                    {/* criteres */}
                    <td className="text-orange-incubate text-md p-5 ">
                      {index + 1 + ")"} {resultat.LIBELLE}
                    </td>
                    {/* Pulseur */}
                    <td className=" ">
                      {resultat.COULEUR === "Vert" ? (
                        <div className="flex justify-center items-center">
                          <BsCircleFill
                            size="45"
                            color="green"
                            className=" text-center"
                          />
                        </div>
                      ) : resultat.COULEUR === "Orange" ? (
                        <div className="flex justify-center items-center">
                          <BsCircleFill size="45" color="orange" />
                        </div>
                      ) : (
                        <div className="flex justify-center items-center">
                          <BsCircleFill size="45" color="red" />
                        </div>
                      )}
                    </td>
                    {/* Tendance */}
                    <td className="">
                      {resultat.TENDANCE === "amelioration" ? (
                        <div className="flex flex-col items-center">
                          <div className="flex flex-col items-center">
                            <FaArrowUp size="40" color="green" />
                            <p>{resultat.TENDANCE.toUpperCase()}</p>
                          </div>
                        </div>
                      ) : resultat.TENDANCE === "stable" ? (
                        <div className="flex flex-col items-center">
                          <div className="flex flex-col items-center">
                            <FaArrowRight size="40" color="orange" />
                            <p>{resultat.TENDANCE.toUpperCase()}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <div className="flex flex-col items-center">
                            <FaArrowDown size="40" color="red" />
                            <p>{resultat.TENDANCE.toUpperCase()}</p>
                          </div>
                        </div>
                      )}
                    </td>
                    {/* Commentaire */}
                    <td >
                      
                      <div className="flex justify-center bg-bleu-incubate rounded-xl shadow-xl p-6 mx-5 h-auto w-96 whitespace-normal ">
                        <p className=" text-left">{resultat.COMMENTAIRE}</p>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      ;
    </>
  );
});

export default ResultatPulseurIndividu;
