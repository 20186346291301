import React, { Component } from "react";
import { withRouter } from "./withRouter";
import { HiOutlineMenu } from "react-icons/hi";
import { AiOutlineLogout} from "react-icons/ai";

class Menu extends Component {
    constructor(props){
        super(props);
        //Variable utilisé dans le composant
        this.state = {
            navbarOpen : false
        };
    }

    //Fonction de redirections
    redirectHomePage = (event) => {
        event.preventDefault();
        this.props.navigate('/');
      }

    redirectDashboardPage = (event) => {
        event.preventDefault();
        this.props.navigate('/dashboard');
    }

    //Fonction d'appel api pour la deconnexion
    deconnexion = (event) => {
        event.preventDefault();

        const requestOptions = {
            method : 'GET', 
            headers : {
                'Content-Type' : 'application/json'
            }
        };
    
  
      fetch('/api/access/logout', requestOptions)
        .then( res => {
          if(res.status === 200) {
            this.props.navigate('/');
          }
        }).catch(err => {
          console.error(err);
        });
    }

    //Fonction pour la navbar
    setNavbarOpen = (event) => {
        event.preventDefault();

        this.setState({navbarOpen : !this.state.navbarOpen});
    }

    //Fonction de rendu
    render(){

        return (
            <>
            <div className="  w-screen md:hidden flex items-center justify-center bg-white ">
                <div className="flex items-center justify-center flex-wrap  border-b-2 rounded-md p-2 py-5  w-11/12">
                    <div className="flex flex-row items-center justify-center w-full">
                        <button className=" justify-center flex items-center" type="button" onClick={this.redirectHomePage} >
                            <img src='/images/Logo_pulseur.png' alt='Logo Pulseur' className="w-2/3"/>
                        </button>
                        <button type="button" onClick={this.setNavbarOpen} className=" lg:hidden flex items-center  text-white transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">
                            <HiOutlineMenu className="fill-current h-8 w-8 text-bleu-incubate"/>
                        </button>
                    </div>

                    <div className={"w-full  2xl:pl-64 flex-grow " + (this.state.navbarOpen ? " flex flex-col justify-center items-center" : " hidden")}>
                        <div className="text-xl md:flex-grow">
                            <button type="button" onClick={this.redirectDashboardPage} className="bloc md:inline-block md:mt-0 text-bleu-incubate transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">Mon espace</button>
                        </div>
                        <div className="text-xl">
                        <button type="button" onClick={this.deconnexion} className="pr-2 bloc md:inline-block md:mt-0 text-bleu-incubate md:mr-4 transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300"><div className="flex flex-row items-center"><p className='px-1'>Deconnexion</p><AiOutlineLogout  className=''/></div></button>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" hidden w-full  flex-col items-center justify-center mb-3 md:flex  ">
                <div className="flex flex-row items-center justify-center border-b-2  p-2   w-11/12">
                    <div className="basis-1/3 flex  items-center justify-start " >
                        <button type="button" onClick={this.redirectHomePage}>
                            <img src='/images/IC_logo_couleurs_orange_nom_cote.png' alt='Logo Be Shake' className=' md:w-2/4 lg:w-2/5'/>
                        </button>
                    </div>
                    <div className="basis-1/3 flex items-center justify-center">
                        <button className=' md:w-2/4 lg:w-1/3' type="button" onClick={this.redirectHomePage} >
                            <img src='/images/Logo_pulseur.png' alt='Logo Be Shake' />
                        </button>
                    </div>
                    <div className="basis-1/3 flex  items-center justify-end text-xl px-2" >
                        <button type="button" onClick={this.redirectDashboardPage} className="pr-2 bloc md:inline-block md:mt-0 text-orange-incubate md:mr-4 transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">Mon espace</button>
                        <button type="button" onClick={this.deconnexion} className="pr-2 bloc md:inline-block md:mt-0 text-orange-incubate md:mr-4 transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300"><div className="flex flex-row items-center"><p className='px-1'>Deconnexion</p><AiOutlineLogout  className=''/></div></button>
                    </div>
                </div>
            </div>
            </>
        );
        }
}

//exports
export default withRouter(Menu);