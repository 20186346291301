import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGlobalFilter,
  useSortBy,
  useTable,
  usePagination,
} from "react-table";
import { FaTrashAlt } from "react-icons/fa";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

export default function CreationPulseur() {
  //Variables utilisées pour la création de pulseur
  const [pulseurModif, setPulseurModif] = useState([]);
  const [pulseurs, setPulseurs] = useState([]);
  const [idSuppr, setIdSuppr] = useState();
  const [libelleEquipe, setLibelleEquipe] = useState();
  const [idSociette, setIdSociette] = useState("");

  //Fonctionnement de la page
  const [loadingPulseurs, setLoadingPulseurs] = useState([true]);
  const navigate = useNavigate();
  const params = useParams();
  const [showModal, setshowModal] = useState(false);

  //equivalent de component did mount pour réalisé des action afin de charger la page avec les bonnes informations
  useEffect(() => {
    fetchPulseurByEquipe();
    setPulseurModif(false);
  }, [pulseurModif]);

  //Permet de récupérer les information de l'équipe
  const fetchEquipe = async () => {
    //Options de requete
    const body = {
      idEquipe: params.id,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/equipes/getEquipeById", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIdSociette(data.ID_SOCIETE);
        setLibelleEquipe(data.LIBELLE_EQUIPE);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //import des Pulseur
  const fetchPulseurByEquipe = async () => {
    const body = {
      idEquipe: params.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/pulseur/getPulseurByEquipe", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPulseurs(data);
        setLoadingPulseurs(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPulseurByEquipe();
    fetchEquipe();
  }, []);

  //Initilisation des éléments du tableau
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ID_PULSEUR",
      },
      {
        Header: "Date de création",
        accessor: "DATE_CREATION",
      },
    ],
    []
  );

  const Data = useMemo(() => [...pulseurs], [pulseurs]);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns.slice(1),
      {
        id: "Voir resultat",
        Header: "",
        Cell: ({ row }) => (
          <button
            className="text-white  p-1 m-1 flex hover:-translate-1 hover:scale-110 duration-300  hover:shadow-xl  rounded-lg bg-orange-incubate"
            onClick={() => {
              navigate(`/resultatPulseur/${row.values.ID_PULSEUR}`);
            }}
          >
            Voir resultat
          </button>
        ),
      },
      {
        id: "Supprimer",
        Header: "",
        Cell: ({ row }) => (
          <button onClick={() => boutonSuppr(row.values.ID_PULSEUR)}>
            <FaTrashAlt className="transition ease-in-out delay-150 hover:-translate-1 hover:scale-150 duration-300" />
          </button>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    {
      columns,
      data: Data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  //Fonction pour afficher le modal de supression
  function boutonSuppr(id) {
    setshowModal(true);
    setIdSuppr(id);
  }

  //Fonction de supression d'un pulseur
  function Supprimer(event, id) {
    event.preventDefault();

    setPulseurModif(true);
    setshowModal(false);

    const body = {
      idPulseur: id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/pulseur/delete", requestOptions)
      .then((res) => {
        if (res.status === 200) {
          alert("Pulseur supprimé !");
        } else {
          alert("Erreur lors de la suppression...");
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //Fonction lancé par le bouton d'jaout et réalisant la création d'un pulseur
  function onClick(event) {
    event.preventDefault();

    const body = {
      idEquipe: params.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/pulseur/", requestOptions)
      .then((res) => {
        if (res.status === 201) {
          alert("Pulseur créé avec succès !");
          setPulseurModif(true);
        } else {
          alert("Erreur lors de la création du Pulseur ...");
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //Fonction permetant de savoir si un numéro de ligne est pair ou non pour pouvoir modifier la couleur une ligne sur deux
  const isEven = (idx) => idx % 2 === 0;

  //Equivalent du render créant l'affichage
  if (loadingPulseurs) {
    return <h1> </h1>;
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full pt-32 ">
        <div className="justify-center inline-flex flex-col  pt-10 w-full px-8">
          <div className="flex flex-col mt-2 mb-2 items-center justify-center py-5">
            <h1 className="text-orange-incubate text-3xl ">
              Pulseurs de l'équipe: {libelleEquipe}
            </h1>
          </div>

          {/*table 1 */}

          <div className="w-screen lg:w-full px-5">
            <table
              className="text-left text-sm md:text-base w-full"
              {...getTableProps()}
            >
              <thead className="bg-orange-incubate text-xs text-white md:text-base">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="px-6 py-1"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : ""}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      className={
                        "border-b hover:bg-orange-incubate hover:bg-opacity-50" +
                        (isEven(idx) ? " bg-orange-incubate bg-opacity-10" : "")
                      }
                    >
                      {row.cells.map((cell, _idx) => (
                        <td className="px-6 py-1" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination mt-2 flex flex-col md:flex-row items-center justify-center">
              <div>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <GrFormPrevious className="" />
                </button>
                <span>
                  <strong>
                    Page {pageIndex + 1} sur {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <GrFormNext className="" />
                </button>
              </div>
              <div className="md:ml-10">
                {"Nombre de questions par page "}
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 30, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5">
            <button
              type="button"
              className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-full text-white mx-2"
              onClick={() => {
                navigate(`/equipePulseur/${idSociette}`);
              }}
            >
              Retour
            </button>
            <button
              type="button"
              className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-full text-white mx-2"
              onClick={onClick}
            >
              Créer Pulseur
            </button>
          </div>
        </div>

        {/*Modal Suppr*/}

        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">ATTENTION</h3>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      Vous êtes sur le point de supprimer un pulseur !
                      <br />
                      Etes vous sûr de vouloir continuer ?
                    </p>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setshowModal(false)}
                    >
                      Annuler
                    </button>
                    <button
                      className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={(event) => Supprimer(event, idSuppr)}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    );
  }
}
