import React, { memo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const GraphiquePulseur = memo((props) => {
  const resultatN = props.greenPercentageN;
  const resultatN1 = props.greenPercentageN1;
  const resultatN2 = props.greenPercentageN2;

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      y: { min: 0, max: 100 ,offset: true},
      x: { offset: true },
    },
    responsive: true,
  };

  const data = {
    labels: ["Pulseur N-2", "Pulseur N-1", "Pulseur Actuel"],
    datasets: [
      {
        label: "Pulseur",
        data: [resultatN2, resultatN1, resultatN],
        backgroundColor: "blue",
        borderColor: "rgb(0,0,255)",
        pointBorderColor: ["blue"],
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="w-96 p-2">
      <Line data={data} options={options}></Line>
    </div>
  );
});

export default GraphiquePulseur;
