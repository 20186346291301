import { useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component) => {
    return (props) => {
        //Fonctionnement de la page
        const navigate = useNavigate();
        const params = useParams();

        //Rendu du composant
        return (
            <Component
                navigate={navigate}
                params={params}
                {...props}
            />
        );
    };
};