import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import Select from 'react-select';

class EditSocietes extends Component {
    constructor(props){
        super(props);
        //Variables utilisé dans la page
        this.state = {
            //Fonctionnement de la page
            showModal : false,
            showModalAnnuler : false,
            listeVisu : [{value : 0, label : 'Non'}, {value : 1, label:'Oui'}],
            modif : false,
            loadingSociete: true,

            //Champs du formulaire
            libelle : '',
            visu : '',

            //Erreur de champs
            libelleError : '',
        }
    }

    //Fonction de edetection d'un changement
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
        this.setState({
            modif: true
          });
    }

    //Fonction de detection d'un changement pour le champ visu
    handleChangeVisu = (event) => {
        this.setState({
          visu : event.value,
        });
        this.setState({
            modif : true,
          });
    }

    //Fonction de validation
    validate = () => {
        let libelleError = '';

        //Validation société
        if(!this.state.libelle){
            libelleError = "Le libelle de la société doit être renseigné"
        }
        if(this.state.libelle.length > 45){
            libelleError = "Le libelle de la société est trop long"
        }


        //Retour d'erreur
        if(libelleError ){
            this.setState({libelleError});
            return false;   
        }else{
            this.setState({libelleError});
            return true; 
        }
    }

    //Fonction de lancement de l'enregistrement des modifiaction vers l'api
    Enregistrer = (event) => {
        event.preventDefault();

        if(this.validate()){
            const body = {
                idSociete : this.props.params.id,
                libelleSociete : this.state.libelle,
                visuSociete : this.state.visu
            };
    
            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/societes/modify', requestOptions)
                .then( res => {
                    if(res.status === 200){
                        alert('Société modifiée avec succès !')
                        this.props.navigate('/societes');
                    }else {
                        alert("Erreur lors de la modification de la société ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }

    //Fonction appellant l'api pour supprimer
    supprimer = (event) => {
        event.preventDefault();

        this.setState({showModal:false});
        
        const body = {
            idSociete : this.props.params.id,
        };

        const requestOptions = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
          };

          fetch('/api/societes/delete', requestOptions)
          .then( res => {
              if(res.status === 200){
                  alert('Société supprimée !')
                  this.props.navigate("/societes");
              }else {
                  alert("Erreur lors de la suppression de la sociéété ...");
                  throw new Error(res.error);
              }
          }).catch(err => {
            console.error(err);
          })
        
        
    }

    //Fonction au chargement de la page pour récupérer les informations d'affichage
    componentDidMount(){
        
        //Récupération de l'individu à modifier
        const body = {
            idSociete : this.props.params.id,
        };

        const getByIdOptions = {
            method : 'POST', 
            body : JSON.stringify(body),
            headers : {
              'Content-Type' : 'application/json'
            }
        };

        fetch('/api/societes/getSocieteById', getByIdOptions)
            .then(res => {
                return res.json();  
            }).then(data => {
                this.setState ({
                    idSociete : data.ID_SOCIETE,
                    libelleSociete : data.LIBELLE_SOCIETE,
                    visu : data.VISU_SOCIETE
                });
                this.setState({loadingSociete : false})
                if(data.ID_SOCIETE === null){
                    this.setState({libelle : ''});
                }else{
                    this.setState({libelle : data.LIBELLE_SOCIETE});
                }
            }).catch(error => {
                console.log(error);
            });
    }

    //Fonction de rendu
    render(){
        if (this.state.loadingSociete){
            return <h1> </h1>
        }else{
            return (
                <div className='flex flex-col items-center justify-center h-full pt-32'>
                    <h1 className="text-orange-incubate text-3xl">Modification d'une société</h1>
                    <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                        <div className="md:mb-4">
                            <label htmlFor='libelle' className="block font-semibold">Libelle</label>
                            <input type='text' name='libelle' placeholder='Libelle' value={this.state.libelle} onChange={this.handleInputChange} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                            {this.state.libelleError ? <div className="text-red-600">{this.state.libelleError}</div> : null }
                        </div>
                        <div className="md:mb-4">
                            <label htmlFor='visu' className="block font-semibold">Les membres de la société peuvent-ils voir les resultats de toute la société ?</label>
                                {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (<blockquote>"{this.state.ariaFocusMessage}"</blockquote>)}
                                <Select
                                    aria-labelledby="Visu-label"
                                    inputId="Visu-input"
                                    name="Visu"
                                    onMenuOpen={this.onMenuOpen}
                                    onMenuClose={this.onMenuClose}
                                    options={this.state.listeVisu}
                                    onChange={this.handleChangeVisu}
                                    defaultValue={this.state.listeVisu[this.state.visu]}
                                />                      
                        </div>
                        <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.Enregistrer}>Enregistrer les modifications</button>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.setState({showModal:true})}} >Supprimer</button>
                            {this.state.modif ? ( <>
                            <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={() => {this.setState({showModalAnnuler:true})}}>Annuler</button>
                            </>)
                        : <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={()=>{this.props.navigate("/societes")}}>Annuler</button> }
                        </div>
                        
                    </form>

                    {/*Modal Suppr*/}

            {this.state.showModal  ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">ATTENTION</h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Vous êtes sur le point de supprimer la société "{this.state.libelle}" !
                            <br />Etes vous sûr de vouloir continuer ?
                        </p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModal:false})}>Annuler</button>
                        <button
                            className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={this.supprimer} >Supprimer</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {/*Modal annuler*/}

            {this.state.showModalAnnuler ? (
                <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">ATTENTION</h3>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                            Vos modifications ne seront pas sauvegardées !
                            <br />Etes vous sûr de vouloir continuer ?
                        </p>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => this.setState({showModalAnnuler:false})}>Non</button>
                        <button type='button' className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={()=>{this.props.navigate("/societes")}}>Oui</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
                </div>
            );
        }
    }
}

//Exports
export default withRouter(EditSocietes);