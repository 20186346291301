import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";

class ProtectedRoute extends Component{
    constructor(props){
        super (props)
        //Variables utilisé dans le composant
        this.state = {
            auth : false,
            loading : true
        }
    }

    //Fonction de chargement des données au chargement du composant
    componentDidMount(){
        const requestOptions = {
            method : 'GET',
            header : {
                'Content-Type' : 'application:json'
            }
        };
        fetch ('/api/checkToken', requestOptions)
            .then(res => {
                if(res.status === 200 || res.status === 304){
                    this.setState({ auth : true, loading : false});
                }else{
                    this.setState({ auth : false, loading : false});
                }
            });
    }

    //Fonction de rendu
    render(){
        if (this.state.loading){
            return <h1> </h1>
        }else{
            return this.state.auth ? <Outlet /> : <Navigate to="/login"/>;
        }
    }
    
}

//exports
export default ProtectedRoute;