import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { BsCircleFill } from "react-icons/bs"

class CreationCriteres extends Component {
    constructor(props){
        super(props);
        this.state = {
            //Champs du formulaire
            idSociete : '',
            libelle : '',
            description : '',
            vert : '',
            rouge : '',
            logo : null,
            imagePreVisu : '',

            //Erreur de champs
            libelleError : '',
            descriptionError : '',
            vertError : '',
            rougeError : '',
            imageError : ''
        }
    }

    //Permet d'effectuer les changement lors de l'ecrit en champ
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name] : value
        });
    }

    //Récupération de l'image ajoutée
    fileSelectedHandler = event => {
        // console.log(event.target.files[0])
        this.setState({logo : event.target.files[0], imagePreVisu : URL.createObjectURL(event.target.files[0])})
    }

    //Fonction de validation des champs
    validate = () => {
        let libelleError = '';
        let descriptionError = '';
        let vertError = '';
        let rougeError = '';
        let imageError = '';

        //Validation libelle
        if(!this.state.libelle){
          libelleError = "Le libelle du critère doit être renseigné"
        }
        //Validation decription
        if(!this.state.description){
          descriptionError = "La description du critère doit être renseignée"
        }
        //Validation vert
        if(!this.state.vert){
          vertError = "La couleur verte du critère doit être renseignée"
        }
        //Validation rouge
        if(!this.state.rouge){
          rougeError = "La couleur rouge du critère doit être renseignée"
        }
        //Validation description
        if(!this.state.logo){
          imageError = "Veuillez ajouter une image"
        }
        


        //Retour d'erreur
        if(libelleError || descriptionError || vertError || rougeError || imageError){
            this.setState({libelleError, descriptionError, vertError, rougeError, imageError});
            return false;   
        }else{
            this.setState({libelleError, descriptionError, vertError, rougeError, imageError});
            return true; 
        }
    }

    //Fonction exécutant la validation et l'appel api au click bouton
    onClick = (event) => {
        event.preventDefault();
        
        if(this.validate()){
            const body = {
                idSociete : this.props.params.id,
                libelle : this.state.libelle,
                description : this.state.description,
                libelle_vert : this.state.vert,
                libelle_rouge : this.state.rouge,
            };

            const requestOptions = {
                method : 'POST', 
                body : JSON.stringify(body),
                headers : {
                  'Content-Type' : 'application/json'
                }
              };
    
            fetch('/api/criteres/', requestOptions)
                .then( res => {
                    if(res.status === 201){
                        //Appel api pour l'enregistrement de l'image
                        const formData = new FormData();
                        formData.append('image', this.state.logo);
                        formData.append('idSociete', this.props.params.id);
                        formData.append('libelle', this.state.libelle);

                        const requestOptionsImage = {
                            method : 'POST', 
                            body : formData,
                            headers:{          
                                'Accept': 'multipart/form-data',
                            },
                            credentials : 'include'
                        };

                        fetch('/api/criteres/upload', requestOptionsImage)
                            .then(response => {
                                if(response.status === 201){
                                    alert('Critère créé avec succès !')
                                    this.props.navigate(`/criteres/${this.props.params.id}`);
                                }else if(response.status === 449){
                                    alert("Le critère à été créé, mais l'image n'as pas été associé car le format n'est pas le bon ... Vous pouvez ajouter une image en modifian le critère.");
                                }else{
                                    alert("Erreur lors de l'ajout de l'image du critère ...");
                                    throw new Error(response.error);
                                }
                            }).catch(err => {
                                console.error(err);
                            })
                    }else if(res.status === 449){
                        alert(`Le critère ${this.state.libelle} est déjà existant pour cette société ...`);
                    }else {
                        alert("Erreur lors de la création du critère ...");
                        throw new Error(res.error);
                    }
                }).catch(err => {
                  console.error(err);
                })
        }
    }


    //Fonction de rendu
    render(){
        return (
            <div className='flex flex-col items-center justify-center h-full pt-32'>
                <h1 className="text-orange-incubate text-3xl">Création d'un critère</h1>
                <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
                  <div className="md:mb-4">
                        <label htmlFor='libelle' className="block font-semibold">Libelle du critère</label>
                        <input type='text' name='libelle' placeholder="Libelle du critère" value={this.state.libelle} onChange={(event)=>this.handleInputChange(event)} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                        {this.state.libelleError ? <div className="text-red-600">{this.state.libelleError}</div> : null }
                    </div>
                    <div className="md:mb-4">
                        <label htmlFor='description' className="block font-semibold">Description</label>
                        <textarea name='description' placeholder="Description" value={this.state.description} onChange={(event)=>this.handleInputChange(event)} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                        {this.state.descriptionError ? <div className="text-red-600">{this.state.descriptionError}</div> : null }
                    </div>
                    <div className="md:mb-4">
                        <label htmlFor='vert' className="block font-semibold text-green-500 text-lg pb-2 pl-2"><BsCircleFill /></label>
                        <textarea name='vert' placeholder="Description du cas vert" value={this.state.vert} onChange={(event)=>this.handleInputChange(event)} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                        {this.state.vertError ? <div className="text-red-600">{this.state.vertError}</div> : null }
                    </div>
                    <div className="md:mb-4">
                        <label htmlFor='rouge' className="block font-semibold text-red-500 text-lg pb-2 pl-2"><BsCircleFill /></label>
                        <textarea name='rouge' placeholder="Description du cas rouge" value={this.state.rouge} onChange={(event)=>this.handleInputChange(event)} className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"/>
                        {this.state.rougeError ? <div className="text-red-600">{this.state.rougeError}</div> : null }
                    </div>
                    <div className="md:mb-4">
                        <label htmlFor='image' className="block font-semibold pb-1">Image</label>
                        <input type="file" name='image' accept="image/*" onChange={this.fileSelectedHandler}/>
                        {this.state.imagePreVisu ? <img src={this.state.imagePreVisu} alt="Image du critère" className="w-96 h-auto"/> : null}
                        {this.state.imageError ? <div className="text-red-600">{this.state.imageError}</div> : null }
                    </div>
                    <div className='flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5'>
                        <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={()=>{this.props.navigate(`/criteres/${this.props.params.id}`)}}>Retour</button>
                        <button type='button' className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2" onClick={this.onClick}>Créer le critère</button>
                    </div>
                </form>
            </div>
        );
        
    }
}

//Export
export default withRouter(CreationCriteres);