import React, { Component } from "react";
import { withRouter } from "./withRouter";
import { HiOutlineMenu } from "react-icons/hi";

class Menu extends Component {
    constructor(props){
        super(props);
        //Variables utilisé dans la page
        this.state = {
            navbarOpen : false
        };
    }

    //Fonction de redirections
    redirectHomePage = (event) => {
        event.preventDefault();
        this.props.navigate('/');
      }

    redirectDashboardPage = (event) => {
        event.preventDefault();
        this.props.navigate('/dashboard');
    }

    
    //Fonction de deconnexion appel api
    deconnexion = (event) => {
        event.preventDefault();

        const requestOptions = {
            method : 'GET', 
            headers : {
                'Content-Type' : 'application/json'
            }
        };
    
  
      fetch('/api/access/logout', requestOptions)
        .then( res => {
          if(res.status === 200) {
            this.props.navigate('/');
          }
        }).catch(err => {
          console.error(err);
        });
    }

    //Fonction gérant la navbar
    setNavbarOpen = (event) => {
        event.preventDefault();

        this.setState({navbarOpen : !this.state.navbarOpen});
    }

    //fonction de rendu
    render(){

        return (
            <>
            <div className="absolute w-screen lg:hidden flex items-center justify-center bg-white" >
                <div className="flex items-center justify-center flex-wrap border-b-2 rounded-md py-5   w-11/12">
                    <div className="flex flex-row items-center justify-center w-full">
                        <button className=" justify-center flex items-center" type="button" onClick={this.redirectHomePage} >
                            <img src='/images/Logo_pulseur.png' alt='Logo Be Shake' className="w-2/3"/>
                        </button>
                        <button type="button" onClick={this.setNavbarOpen} className=" lg:hidden flex items-center  text-white transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">
                            <HiOutlineMenu className="fill-current h-8 w-8 text-bleu-incubate"/>
                        </button>
                    </div>

                    <div className={"w-full  2xl:pl-64 md:flex-grow " + (this.state.navbarOpen ? " flex flex-col justify-center items-center" : " hidden")}>
                        <div className="text-xl md:flex-grow">
                            <a href="https://www.incubate-conseil.com/"><button type="button"  className="bloc md:inline-block md:mt-0 text-bleu-incubate transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">Qui sommes nous ?</button></a>
                        </div>
                        <div className="text-xl md:flex-grow">
                            <a href="https://www.incubate-conseil.com/contact/"><button type="button"  className="bloc md:inline-block md:mt-0 text-bleu-incubate transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300">Nous contacter</button></a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="absolute hidden w-full  flex-col items-center justify-center   lg:flex  ">
                <div className="flex flex-row items-center justify-center border-b-2 p-2 w-11/12">
                <div className="basis-1/3 flex  items-center justify-start " >
                        <button type="button" onClick={this.redirectHomePage}>
                            <img src='/images/Logo_pulseur.png' alt='Logo Be Shake' className=' md:w-2/4 lg:w-48'/>
                        </button>
                    </div>
                    <div className="basis-1/3 flex items-center justify-center">
                        
                    </div>
                    <div className="basis-1/3 flex  items-center justify-end text-xl px-2 p-5" >
              <a href="https://www.incubate-conseil.com/"><button type="button" className=" pr-2 bloc md:inline-block md:mt-0 text-bleu-incubate md:mr-4 transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300"><p>Qui sommes nous ?</p></button></a>
              <a href="https://www.incubate-conseil.com/contact/"><button type="button" className="pr-2 bloc md:inline-block md:mt-0 text-bleu-incubate md:mr-4 transition ease-in-out delay-150 hover:translate-1 hover:scale-110 duration-300"><p>Nous contacter</p></button></a>
            </div>
                </div>
            </div>
            </>
        );
        }
}

export default withRouter(Menu);