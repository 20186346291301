import React, { Component } from "react";
import { withRouter } from "../Outils/withRouter.jsx";
import { GoTrashcan } from "react-icons/go";
import Select from "react-select";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Fonctionnement de la page
      societe: "",
      loadingIndivi: true,
      loadingProfil: true,
      loadingSociete: true,
      loadingEquipe: true,
      listeProfils: [],
      listeSocietes: [],
      listeEquipes: [],
      listeEquipesDel: [],
      listeEquipesAdd: [],
      
     
      selectedSociete: "",

      showModal: false,
      showModalAnnuler: false,
      modif: false,

      //Champs du formulaire
      id: "",
      prenom: "",
      nom: "",
      profil: "",
      equipe: [],
      
      equipemodif: "",
      profilmodif: "",

      //Erreur de champs
      prenomError: "",
      nomError: "",
    };
  }

  //handleChange

  handleChangeProfil = (event) => {
    this.setState({
      profil: event,
    });
    this.setState({
      modif: true,
    });
  };

  handleChangeSociete = (event) => {
    this.setState({
      societe: event,
    });
    this.setState({
      modif: true,
    });
    this.getEquipe(event);
  };

  handleChangeEquipe = async (event) => {
    //equipe ajoutée

    if (this.state.equipe.length < event.length) {
      let tmp = [];
      tmp.push(event.filter((x) => !this.state.equipe.includes(x)));
      tmp.forEach((equipe) => {
        this.state.listeEquipesAdd.push(equipe[0]);
      });
    }
    //equipe supprimée
    else {
      let tmp = [];
      tmp.push(this.state.equipe.filter((x) => !event.includes(x)));

      if (this.state.listeEquipesAdd.length === 0) {
        tmp.forEach((equipe) => {
          this.state.listeEquipesDel.push(equipe[0]);
        });
      } else {
        for (let i = 0; i < this.state.listeEquipesAdd.length; i++) {
          if (this.state.listeEquipesAdd[i].value === tmp[0][0].value) {
            this.state.listeEquipesAdd.splice(i, i + 1);
          } else if (i === this.state.listeEquipesAdd.length - 1) {
            tmp.forEach((equipe) => {
              this.state.listeEquipesDel.push(equipe[0]);
            });
          }
        }
      }
    }
    this.setState({
      equipe: event,
    });
    this.setState({
      modif: true,
    });
  };

  //handleChange pour profil

  handleChange2 = (event) => {
    this.setState({
      profil: event.value,
    });
    this.setState({
      modif: true,
    });
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      modif: true,
    });
  };

  validate = () => {
    let prenomError = "";
    let nomError = "";
    let profilError = "";

    //Validation prenom
    if (!this.state.prenom) {
      prenomError = "Le prénom doit être renseigné";
    }
    if (this.state.prenom.length > 45) {
      prenomError = "Le prenom est trop long";
    }

    //Validation nom
    if (!this.state.nom) {
      nomError = "Le nom doit être renseigné";
    }
    if (this.state.nom.length > 45) {
      nomError = "Le nom est trop long";
    }

    //validation profil
    if (!this.state.profil) {
      profilError = "Le profil doit être renseigné";
    }

    //Retour d'erreur
    if (prenomError || nomError || profilError) {
      this.setState({ prenomError, nomError, profilError });
      return false;
    } else {
      this.setState({ prenomError, nomError, profilError });
      return true;
    }
  };

  modifier = (event) => {
    event.preventDefault();
    if (this.validate()) {
      const body = {
        id: this.state.id,
        nom: this.state.nom,
        prenom: this.state.prenom,
        mail: this.state.mail,
        profil: this.state.profil.value,
        societe: this.state.societe,
        delEquipe: this.state.listeEquipesDel,
        equipe: this.state.listeEquipesAdd,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/register/modify", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            alert("Utilisateur modifé avec succès !");
            this.props.navigate("/users");
          } else {
            alert("Erreur lors de la modification de l'utilisateur ...");
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  resetPassword = (event) => {
    event.preventDefault();
    const body = {
      id: this.state.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/register/resetPassword", requestOptions)
      .then((res) => {
        if (res.status === 201) {
          alert("Mot de passe réinitialisé avec succès !");
        } else {
          alert("Erreur lors de la réinitialisation du mot de passe ...");
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  supprimer = (event) => {
    event.preventDefault();

    this.setState({ showModal: false });

    const body = {
      id: this.state.id,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch("/api/register/deleteIndividu", requestOptions)
      .then((res) => {
        if (res.status === 201) {
          alert("Individu supprimé !");
          this.props.navigate("/users");
        } else {
          alert("Erreur lors de la suppression de l'individu ...");
          throw new Error(res.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getEquipe = (societe) => {
    const body = {
      idSociete: societe,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    //liste des equipes
    fetch("/api/equipes/getAllEquipes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let equipes = data.map((equipe) => {
          return { value: equipe.ID_EQUIPE, label: equipe.LIBELLE_EQUIPE };
        });
        this.setState({
          listeEquipes: equipes,
          loadingEquipe: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  findSociette = (societes) => {
    // if (!this.state.societe || !this.state.societe.label) {
    //   console.error(
    //     "Le label de la société n'existe pas dans l'état ou l'état n'est pas prêt."
    //   );
    //   return;
    // }

    if (societes.length > 0) {
      let selectedSociete = societes.find(
        (societe) => societe.label === this.state.societe.label
      );

      if (selectedSociete) {
        this.setState({
          selectedSociete: selectedSociete.value,
        });
        this.getEquipe(selectedSociete.value);
      } else {
        console.error("Aucune société correspondante n'a été trouvée");
      }
    } else {
      console.error("Aucune société n'a été trouvée dans la liste");
    }
  };

  componentDidMount() {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des profils
    fetch("/api/register/getProfil", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let profils = data.map((profil) => {
          return { value: profil.ID_PROFIL, label: profil.LIBELLE_PROFIL };
        });

        this.setState({
          listeProfils: profils,
        });
        this.setState({ loadingProfil: false });
      })
      .catch((error) => {
        console.log(error);
      });

    //Récupération de l'individu à modifier
    const body = {
      id: this.props.params.id,
    };

    const getByIdOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch("/api/register/getIndividuById", getByIdOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          id: data.ID_INDIVIDU,
          nom: data.NOM,
          prenom: data.PRENOM,
          profil: { value: data.ID_PROFIL, label: data.LIBELLE_PROFIL },
        });
        if (data.ID_SOCIETE === null) {
          this.setState({ societe: "" });
        } else {
          this.setState({
            societe: { value: data.ID_SOCIETE, label: data.LIBELLE_SOCIETE },
          });
        }
        this.setState({ equipe: data.equipes });
        this.setState({ loadingIndivi: false });
      })
      .catch((error) => {
        console.log(error);
      });

    fetch("/api/societes/getAllSocietes", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // let societes = data.map((societe) => {
        //   return { value: societe.ID_SOCIETE, label: societe.LIBELLE_SOCIETE };
        // });

        this.setState(
          {
            listeSocietes: data.map((societe) => ({
              value: societe.ID_SOCIETE,
              label: societe.LIBELLE_SOCIETE,
            })),
            loadingSociete: false,
          },
          () => {
            if (this.state.societe && this.state.societe.label) {
              this.findSociette(this.state.listeSocietes);
            } else {
              console.warn(
                "Aucune société n'a été sélectionnée ou l'état 'societe' est indéfini."
              );
            }
          }
          
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    if (
      this.state.loadingProfil ||
      this.state.loadingSociete ||
      this.state.loadingIndivi ||
      this.state.loadingEquipe 
    ) {
      return <h1>Loading... </h1>;
    } else {
      return (
        <div className="flex flex-col items-center justify-center h-full pt-32">
          <h1 className="text-orange-incubate text-3xl">
            Modification d'un utilisateur
          </h1>
          <form className="w-11/12 mt-5 md:mt-16 lg:mt-8 xl:mt-5 md:w-5/6 lg:w-3/4 xl:w-1/2">
            <div className="md:mb-4">
              <label htmlFor="nom" className="block font-semibold">
                Nom
              </label>
              <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={this.state.nom}
                onChange={this.handleInputChange}
                className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"
              />
              {this.state.nomError ? (
                <div className="text-red-600">{this.state.nomError}</div>
              ) : null}
            </div>
            <div className="md:mb-4">
              <label
                htmlFor="prenom"
                className="block text-l font-semibold mb-2">
                Prenom
              </label>
              <input
                type="text"
                name="prenom"
                placeholder="Prenom"
                value={this.state.prenom}
                onChange={this.handleInputChange}
                className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"
              />
              {this.state.prenomError ? (
                <div className="text-red-600">{this.state.prenomError}</div>
              ) : null}
            </div>
            <div className="md:mb-4">
              <label
                htmlFor="profil"
                className="block text-l font-semibold mb-2">
                Profil
              </label>
              {!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
              )}
              <Select
                aria-labelledby="Profil-label"
                inputId="Profil-input"
                name="Profil"
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                options={this.state.listeProfils}
                onChange={this.handleChangeProfil}
                defaultValue={this.state.profil}
              />
              {this.state.profilError ? (
                <div className="text-red-600">{this.state.profilError}</div>
              ) : null}
            </div>
            <div className="md:mb-4">
              <label
                htmlFor="societe"
                className="block text-l font-semibold mb-2">
                Societe
              </label>
              {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
              )}
              {this.state.equipe.length === 0 ? (
                <Select
                  aria-labelledby="Societe-label"
                  inputId="Societe-input"
                  name="Societe"
                  onMenuOpen={this.onMenuOpen}
                  onMenuClose={this.onMenuClose}
                  options={this.state.listeSocietes}
                  onChange={this.handleChangeSociete}
                  defaultValue={this.state.societe}
                />
              ) : (
                <input
                  type="text"
                  name="libelle"
                  placeholder="Societe"
                  value={this.state.societe.label}
                  onChange={(event) => this.handleInputChange(event)}
                  className="bg-gray-300 shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"
                />
              )}
            </div>
            <div className="md:mb-4">
              <label
                htmlFor="equipe"
                className="block text-l font-semibold mb-2">
                Equipe
              </label>
              {!!this.state.ariaFocusMessage && !!this.state.isMenuOpen && (
                <blockquote>"{this.state.ariaFocusMessage}"</blockquote>
              )}
              <Select
                aria-labelledby="Equipe-label"
                inputId="Equipe-input"
                name="Equipe"
                isMulti
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                options={this.state.listeEquipes}
                onChange={this.handleChangeEquipe}
                defaultValue={this.state.equipe}
              />
              {this.state.equipeError ? (
                <div className="text-red-600">{this.state.equipeError}</div>
              ) : null}
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center mt-5 md:mt-16 lg:mt-10 mb-5">
              <button
                type="button"
                className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"
                onClick={this.modifier}>
                Modifier l'utilisateur
              </button>
              <button
                type="button"
                className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"
                onClick={this.resetPassword}>
                Réinitialiser le mot de passe
              </button>
              <button
                type="button"
                className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"
                onClick={() => {
                  this.setState({ showModal: true });
                }}>
                <GoTrashcan className="h-7 w-10" />
              </button>
              {this.state.modif ? (
                <>
                  <button
                    type="button"
                    className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"
                    onClick={() => {
                      this.setState({ showModalAnnuler: true });
                    }}>
                    Annuler
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="transition ease-in-out delay-150 bg-orange-incubate hover:-translate-1 hover:scale-110 duration-300 font-bold text-lg py-2 px-3 mb-5 rounded-lg text-white mx-2"
                  onClick={() => {
                    this.props.navigate("/users");
                  }}>
                  Annuler
                </button>
              )}
            </div>
          </form>
          {this.state.showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">ATTENTION</h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Vous êtes sur le point de supprimer l'utilisateur{" "}
                        {this.state.nom} {this.state.prenom}!
                        <br />
                        Etes vous sûr de vouloir continuer ?
                      </p>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b ">
                      <button
                        className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => this.setState({ showModal: false })}>
                        Annuler
                      </button>
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={this.supprimer}>
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          {/*Modal annuler*/}

          {this.state.showModalAnnuler ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">ATTENTION</h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Vos modifications ne seront pas sauvegardées !
                        <br />
                        Etes vous sûr de vouloir continuer ?
                      </p>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() =>
                          this.setState({ showModalAnnuler: false })
                        }>
                        Non
                      </button>
                      <button
                        type="button"
                        className="bg-orange-incubate text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => {
                          this.props.navigate("/users");
                        }}>
                        Oui
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      );
    }
  }
}

export default withRouter(EditUser);
