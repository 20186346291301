import React, { memo } from "react";
import ReactSpeedometer from "react-d3-speedometer";

const Tendance = memo((props) => {
  const test = props.resultat;
 

  /**
   * Calcul du pourcentage des réponses différentes de "amelioration" par rapport au nombre total de réponses "amelioration".
   * @param {Array} responses - Le tableau contenant les réponses au questionnaire avec les critères "amelioration", "deterioration" et "stabilite".
   * @returns {Number} - Le pourcentage des réponses différentes de "amelioration" par rapport au nombre total de réponses "amelioration".
   */
  

 

  return (
    <div className="flex justify-center ">
      <div>
        <ReactSpeedometer
          value={test} // Utilise la valeur dynamique
          minValue={0}
          maxValue={100}
          startColor="red"
          endColor="green"
          segments={6}
          needleColor="black"
          width={220} // Ajustez la largeur du compteur ici pour le rendre plus petit
          height={170}
        />
      </div>
    </div>
  );
});

export default Tendance;
