import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { BsTrash } from "react-icons/bs";
import Select from "react-select";
import { MdAddCircle } from "react-icons/md";

const PlanAction = memo(() => {
  const params = useParams();

  const [actionCritere, setActionCritere] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [modalshow, setModalShow] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState({
    ID_CRITERE: "",
    ID_ACTION: "",
    ID_PULSEUR: "",
  });
  const [modalDelete, setModalDelete] = useState(false);
  const [donneesFiltrees, setDonneesFiltrees] = useState(actionCritere);
  const [filtresSelectionnes, setFiltresSelectionnes] = useState({
    statut: [],
    niveau: [],
    priorite: [],
    libelle: "",
    // Ajoutez d'autres filtres au besoin
  });
  const [description, setDescription] = useState("");
  const [idStatus, setIdStatus] = useState("");
  const [idNiveau, setIdNiveau] = useState("");
  const [idPriorite, setIdPriorite] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [niveauError, setNiveauError] = useState("");
  const [prioriteError, setPrioriteError] = useState("");
  const [allStatus, setAllStatus] = useState([]);
  const [allNiveau, setAllNiveau] = useState([]);
  const [allPriorite, setAllPriorite] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalNiveau, setModalNiveau] = useState(false);
  const [modalPriorite, setModalPriorite] = useState(false);
  const [inputStatus, setInputStatus] = useState("");
  const [inputNiveau, setInputNiveau] = useState("");
  const [inputPriorite, setInputPriorite] = useState("");
  const [newStatut, setNewStatut] = useState("");
  const [newStatutError, setNewStatutError] = useState("");
  const [newNiveau, setNewNiveau] = useState("");
  const [newNiveauError, setNewNiveauError] = useState("");
  const [newPriorite, setNewPriorite] = useState("");
  const [newPrioriteError, setNewPrioriteError] = useState("");

  const handleInputChangeNewPriorite = (e) => {
    setNewPriorite(e.target.value);
  };

  const ValidateNewPriorite = () => {
    const newPrioriteValue = newPriorite.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newStatut ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newPrioriteValue === "") {
      setNewPrioriteError("Le champ ne doit pas être vide.");
      return false;
    } else if (!lettersRegex.test(newPrioriteValue)) {
      setNewPrioriteError("Le champ ne doit contenir que des lettres.");
      return false;
    } else {
      setNewPrioriteError("");
      return true;
    }
  };

  const RegisterPriorite = () => {
    if (ValidateNewPriorite()) {
      const body = {
        method: "POST",
        body: JSON.stringify({ newPriorite: newPriorite }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/action/registerPriorite", body).then((res) => {
        if (res.status === 201) {
          alert("la priorite a été creer avec succès !");
          setModalPriorite(false);
          setNewPriorite("");
          fetchPriorite();
        } else {
          alert("Erreur lors de la création de la priorite...");
          setModalPriorite(false);
          setNewPriorite("");
          throw new Error(res.error);
        }
      });
    }
  };

  const handleInputChangeNewNiveau = (e) => {
    setNewNiveau(e.target.value);
  };

  const ValidateNewNiveau = () => {
    const newNiveauValue = newNiveau.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newNiveau ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newNiveauValue === "") {
      setNewNiveauError("Le champ ne doit pas être vide.");
      return false;
    } else if (!lettersRegex.test(newNiveauValue)) {
      setNewNiveauError("Le champ ne doit contenir que des lettres.");
      return false;
    } else {
      setNewNiveauError("");
      return true;
    }
  };

  const RegisterNiveau = () => {
    if (ValidateNewNiveau()) {
      const body = {
        method: "POST",
        body: JSON.stringify({ newNiveau: newNiveau }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/action/registerNiveau", body).then((res) => {
        if (res.status === 201) {
          alert("le niveau a été creer avec succès !");
          setModalNiveau(false);
          setNewNiveau("");
          fetchNiveau();
        } else {
          alert("Erreur lors de la création du niveau ...");
          setModalNiveau(false);
          setNewNiveau("");
          throw new Error(res.error);
        }
      });
    }
  };

  const handleInputChangeNewStatut = (e) => {
    setNewStatut(e.target.value);
  };

  const ValidateNewStatut = () => {
    const newStatutValue = newStatut.trim(); // Supprimer les espaces avant et après le texte

    // Expression régulière pour vérifier que le champ newStatut ne contient que des lettres
    const lettersRegex = /^[A-Za-z\s]+$/;

    if (newStatutValue === "") {
      setNewStatutError("Le champ ne doit pas être vide.");
      return false;
    } else if (!lettersRegex.test(newStatutValue)) {
      setNewStatutError("Le champ ne doit contenir que des lettres.");
      return false;
    } else {
      setNewStatutError("");
      return true;
    }
  };

  const RegisterStatut = () => {
    if (ValidateNewStatut()) {
      const body = {
        method: "POST",
        body: JSON.stringify({ newStatut: newStatut }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch("/api/action/registerStatut", body).then((res) => {
        if (res.status === 201) {
          alert("le statut a été creer avec succès !");
          setModalStatus(false);
          setNewStatut("");
          fetchStatus();
        } else {
          alert("Erreur lors de la création du statut ...");
          setModalStatus(false);
          setNewStatut("");
          throw new Error(res.error);
        }
      });
    }
  };

  //handleChange pour les autres champs
  const handleChangePriorite = (event) => {
    setIdPriorite(event.value);
  };
  const handleChangeNiveau = (event) => {
    setIdNiveau(event.value);
  };
  const handleChangeStatus = (event) => {
    setIdStatus(event.value);
  };
  const handleOptionChangeDescription = (changeEvent) => {
    setDescription(changeEvent.target.value);
  };
  const handleChangeTextStatus = (changeEvent) => {
    setInputStatus(changeEvent.target.value);
  };
  const handleChangeTextNiveau = (changeEvent) => {
    setInputNiveau(changeEvent.target.value);
  };

  const handleChangeTextPriorite = (changeEvent) => {
    setInputPriorite(changeEvent.target.value);
  };

  const validate = () => {
    let descriptionError = "";
    let statusError = "";
    let niveauError = "";
    let prioriteError = "";

    //Validation prenom
    if (!description) {
      descriptionError = "Entrer une description svp";
    }

    //Validation nom
    if (!idStatus) {
      statusError = "un status doit être renseigné";
    }

    //Validation mail
    if (!idNiveau) {
      niveauError = "un niveau doit être renseigné";
    }
    if (!idPriorite) {
      prioriteError = "un niveau doit être renseigné";
    }

    //Retour d'erreur
    if (descriptionError || statusError || niveauError || prioriteError) {
      setDescriptionError(descriptionError);
      setNiveauError(niveauError);
      setStatusError(statusError);
      setPrioriteError(prioriteError);
      return false;
    } else {
      setDescriptionError(descriptionError);
      setNiveauError(niveauError);
      setStatusError(statusError);
      setPrioriteError(prioriteError);
      return true;
    }
  };

  const UpdateAction = (event) => {
    event.preventDefault();

    if (validate()) {
      const body = {
        idAction: currentModalIndex.ID_ACTION,
        description: description,
        idStatus: idStatus,
        idNiveau: idNiveau,
        idPriorite: idPriorite,
      };

      const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch("/api/action/update", requestOptions)
        .then((res) => {
          if (res.status === 201) {
            ActionData();
            alert("L'action modifé avec succès !");
            setModalShow(false);
          } else {
            alert("Erreur lors de la modification de l'action...");
            setModalShow(false);
            throw new Error(res.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const DeleteAction = async (event) => {
    event.preventDefault();

    const body = {
      idAction: currentModalIndex.ID_ACTION,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/action/delete", requestOptions)
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          ActionData();
          alert("Action supprimée");
          setModalDelete(false);
        } else {
          alert("Action non supprimée");
          setModalDelete(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Fonction pour gérer le changement de statut de la checkbox
  const handleCheckboxChange = (type, valeur) => {
    setFiltresSelectionnes((prevState) => ({
      ...prevState,
      [type]: prevState[type].includes(valeur)
        ? prevState[type].filter((item) => item !== valeur)
        : [...prevState[type], valeur],
    }));
  };

  const handleLibelleChange = (e) => {
    setFiltresSelectionnes((prevState) => ({
      ...prevState,
      libelle: e.target.value,
    }));
  };

  // Fonction pour appliquer les filtres
  const appliquerFiltres = () => {
    let filtre = actionCritere; // Remplacez 'donnees' par le nom de votre objet

    // Appliquer le filtre par statut
    if (filtresSelectionnes.statut.length > 0) {
      filtre = filtre.filter((item) =>
        filtresSelectionnes.statut.includes(item.LIBELLE_STATUS)
      );
    }

    // Appliquer le filtre par niveau
    if (filtresSelectionnes.niveau.length > 0) {
      filtre = filtre.filter((item) =>
        filtresSelectionnes.niveau.includes(item.LIBELLE_NIVEAU)
      );
    }

    // Appliquer le filtre par priorité
    if (filtresSelectionnes.priorite.length > 0) {
      filtre = filtre.filter((item) =>
        filtresSelectionnes.priorite.includes(item.LIBELLE_PRIORITE)
      );
    }

    // Appliquer le filtre par libellé
    if (filtresSelectionnes.libelle !== "") {
      filtre = filtre.filter((item) =>
        item.LIBELLE.toLowerCase().includes(
          filtresSelectionnes.libelle.toLowerCase()
        )
      );
    }

    // Ajoutez d'autres filtres au besoin

    setDonneesFiltrees(filtre);
  };

  const ActionData = async () => {
    const bodyIdPulseur = {
      idEquipe: params.id,
    };

    const get = {
      method: "POST",
      body: JSON.stringify(bodyIdPulseur),
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch("/api/action/getActionByEquipe", get)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setActionCritere(data);
        setDonneesFiltrees(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchStatus = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllStatus", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllStatus(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchNiveau = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllNiveau", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllNiveau(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPriorite = async () => {
    //Options de requete
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    //liste des societes
    fetch("/api/action/getAllPriorite", requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAllPriorite(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      header: {
        "Content-Type": "application:json",
      },
    };

    fetch("/api/isAdmin", requestOptions).then((res) => {
      if (res.status === 200 || res.status === 304) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });

    fetchPriorite();
    fetchNiveau();
    fetchStatus();

    ActionData();
  }, []);

  if (isAdmin) {
    return (
      <>
        <h1 className="text-center text-orange-incubate text-xl mt-1">
          Plan d'action
        </h1>
        <div className="flex justify-center items-start mt-3 w-2/3 mx-auto">
          {/* FILTRE */}
          <div className=" rounded-md shadow-lg p-3 mr-5">
            <h2 className="font-bold text-center text-md mb-2">
              Choisissez vos filtres
            </h2>
            {allStatus.map((status) => (
              <div key={status.LIBELLE}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxChange("statut", status.LIBELLE)
                    }
                    checked={filtresSelectionnes.statut.includes(
                      status.LIBELLE
                    )}
                    className="mr-2"
                  />
                  <span className="text-red-900">Statut</span> {status.LIBELLE}
                </label>
              </div>
            ))}

            {allNiveau.map((niveau) => (
              <div key={niveau.LIBELLE}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxChange("niveau", niveau.LIBELLE)
                    }
                    checked={filtresSelectionnes.niveau.includes(
                      niveau.LIBELLE
                    )}
                    className="mr-2"
                  />
                  <span className="text-green-900">Niveau</span>{" "}
                  {niveau.LIBELLE}
                </label>
              </div>
            ))}

            {allPriorite.map((priorite) => (
              <div key={priorite.LIBELLE}>
                <label>
                  <input
                    type="checkbox"
                    onChange={() =>
                      handleCheckboxChange("priorite", priorite.LIBELLE)
                    }
                    checked={filtresSelectionnes.priorite.includes(
                      priorite.LIBELLE
                    )}
                    className="mr-2"
                  />
                  <span className="text-blue-900">Priorite</span>{" "}
                  {priorite.LIBELLE}
                </label>
              </div>
            ))}

            <div>
              <label>
                Filtrer par Critères :
                <input
                  type="text"
                  value={filtresSelectionnes.libelle}
                  onChange={handleLibelleChange}
                  className="border border-black rounded-md ml-2"
                />
              </label>
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="justify-center my-2 mx-2 text-sm py-2 px-5 flex hover:-translate-1 hover:scale-90 duration-300  hover:shadow-xl  rounded-lg bg-green-200"
                onClick={appliquerFiltres}>
                Appliquer les filtres
              </button>
            </div>
          </div>
          {/* Ajoutez d'autres checkboxes pour d'autres valeurs de NIVEAU si nécessaire */}

          {/* tableau plan action */}
          <table className="shadow-xl rounded-xl w-full ">
            <thead>
              {" "}
              <tr className="text-center border border-black">
                <th className="text-md text-bleu-incubate  bg-blue-50 border border-black">
                  Criteres
                </th>
                <th className="text-md text-bleu-incubate  bg-blue-50 border border-black">
                  Date
                </th>
                <th className="text-md text-orange-incubate  bg-blue-50 border border-black">
                  Description
                </th>
                <th className="text-md text-orange-incubate  bg-blue-50 border border-black">
                  Statut
                </th>
                <th className="text-md text-orange-incubate  bg-blue-50 border border-black">
                  Niveau
                </th>
                <th className="text-md text-orange-incubate  bg-blue-50 border border-black">
                  Priorite
                </th>
                <th className="text-md text-orange-incubate  bg-blue-50 border border-black">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {donneesFiltrees.length === 0 ? (
                <div className="flex justify-center">
                  {" "}
                  <p className="text center text-xl font-bold p-5">
                    Il n'y a pas de plan d'action.
                  </p>
                </div>
              ) : (
                <>
                  {donneesFiltrees.map((action, index) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className="items-center shadow-sm rounded-lg text-center border border-black ">
                        {/* criteres */}
                        <td className="text-orange-incubate text-md p-5 border border-black">
                          {action.LIBELLE}
                        </td>
                        {/* date */}
                        <td className="text-black text-md p-5 bg-blue-50 border border-black">
                          {action.DATE}
                          {action.DATE_MODIF !== null ? (
                            <p className="text-xs font-bold mt-2">
                              Modifié le : {action.DATE_MODIF}
                            </p>
                          ) : null}
                        </td>
                        {/* Description */}
                        <td className="text-left bg-green-50 p-5 border border-black">
                          {action.DESCRIPTION}
                        </td>
                        {/* Tendance */}
                        <td className="bg-orange-50 p-5 border border-black">
                          {action.LIBELLE_STATUS}{" "}
                        </td>
                        {/* Commentaire */}
                        <td className="bg-red-50 p-5 border border-black">
                          {action.LIBELLE_NIVEAU}{" "}
                        </td>
                        <td className="bg-yellow-50 p-5 border border-black">
                          {action.LIBELLE_PRIORITE}{" "}
                        </td>

                        {/* Modifier */}
                        <td className="bg-pink-50 p-5 border border-black">
                          <div className="flex justify-center space-x-5">
                            <button
                              className=" p-2 rounded-md shadow-lg"
                              onClick={() => {
                                setModalShow(true);
                                setCurrentModalIndex(() => ({
                                  ID_CRITERE: action.ID_CRITERE,
                                  ID_ACTION: action.ID_ACTION,
                                  ID_PULSEUR: action.ID_PULSEUR,
                                  index: index,
                                }));
                              }}>
                              <BsFillPencilFill />
                            </button>

                            <button
                              className=" p-2 rounded-md shadow-lg"
                              onClick={() => {
                                setModalDelete(true);
                                setCurrentModalIndex(() => ({
                                  ID_CRITERE: action.ID_CRITERE,
                                  ID_ACTION: action.ID_ACTION,
                                  ID_PULSEUR: action.ID_PULSEUR,
                                }));
                              }}>
                              <BsTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {modalshow ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold text-orange-incubate">
                      Action {currentModalIndex.ID_ACTION}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModalShow(false)}>
                      <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}

                  <div className="relative p-6 flex-auto">
                    <form className="">
                      <div className="md:mb-4">
                        <label
                          htmlFor="description"
                          className="block font-semibold">
                          Description
                        </label>
                        <textarea
                          onChange={handleOptionChangeDescription}
                          value={donneesFiltrees.DESCRIPTION}
                          rows="4"
                          className="w-full shadow appearance-none border rounded-lg text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2"
                          placeholder="...."
                          required>
                          {
                            donneesFiltrees[currentModalIndex.index].DESCRIPTION
                            /* {
                            actionCritere[currentModalIndex.ID_CRITERE]
                              .DESCRIPTION
                          } */
                          }
                        </textarea>
                        {descriptionError ? (
                          <div className="text-red-600">{descriptionError}</div>
                        ) : null}
                      </div>

                      <div className="w-11/12 mb-3 flex space-x-6 items-center">
                        <div className="w-full">
                          {" "}
                          <Select
                            aria-labelledby="Status-label"
                            inputId="Status-input"
                            name="Status"
                            placeholder={
                              donneesFiltrees[currentModalIndex.index]
                                .LIBELLE_STATUS
                            }
                            options={allStatus.map((status) => ({
                              value: status.ID_STATUS,
                              label: status.LIBELLE,
                            }))}
                            onChange={handleChangeStatus}
                          />
                          {niveauError ? (
                            <div className="text-red-600">{statusError}</div>
                          ) : null}
                        </div>
                        <button
                          onClick={() => {
                            setModalStatus(true);
                            setModalShow(false);
                          }}>
                          <MdAddCircle size={20} color="orange" />
                        </button>
                      </div>

                      <div className="w-11/12 mb-3 flex space-x-6 items-center">
                        <div className="w-full">
                          {" "}
                          <Select
                            aria-labelledby="Niveau-label"
                            inputId="Niveau-input"
                            name="Niveau"
                            placeholder={
                              donneesFiltrees[currentModalIndex.index]
                                .LIBELLE_NIVEAU
                            }
                            options={allNiveau.map((niveau) => ({
                              value: niveau.ID_NIVEAU,
                              label: niveau.LIBELLE,
                            }))}
                            onChange={handleChangeNiveau}
                          />
                          {niveauError ? (
                            <div className="text-red-600">{niveauError}</div>
                          ) : null}
                        </div>

                        <button
                          onClick={() => {
                            setModalNiveau(true);
                            setModalShow(false);
                          }}>
                          <MdAddCircle size={20} color="orange" />
                        </button>
                      </div>

                      <div className="w-11/12 mb-3 flex space-x-6 items-center">
                        <div className="w-full">
                          {" "}
                          <Select
                            aria-labelledby="Priorite-label"
                            inputId="Priorite-input"
                            name="Priorite"
                            placeholder={
                              donneesFiltrees[currentModalIndex.index]
                                .LIBELLE_PRIORITE
                            }
                            options={allPriorite.map((priorite) => ({
                              value: priorite.ID_PRIORITE,
                              label: priorite.LIBELLE,
                            }))}
                            onChange={handleChangePriorite}
                          />
                          {prioriteError ? (
                            <div className="text-red-600">{prioriteError}</div>
                          ) : null}
                        </div>

                        <button
                          onClick={() => {
                            setModalPriorite(true);
                            setModalShow(false);
                          }}>
                          <MdAddCircle size={20} color="orange" />
                        </button>
                      </div>
                    </form>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setModalShow(false);
                      }}>
                      Fermer
                    </button>
                    <button
                      className="bg-green-800 text-white active:bg-green-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={UpdateAction}>
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {/* Modal Delete */}
        {modalDelete ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold text-orange-incubate">
                      Action{currentModalIndex.ID_CRITERE}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModalDelete(false)}>
                      <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}

                  <div className="relative p-6 flex-auto">
                    <p>Êtes-vous sure de vouloir supprimer cette action</p>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="bg-red-800 active:bg-red-800 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setModalDelete(false);
                      }}>
                      Non
                    </button>
                    <button
                      className=" bg-green-800 active:bg-green-800 font-bold uppercase  text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={DeleteAction}>
                      Oui
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {/* Modal status */}
        {modalStatus ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold text-orange-incubate">
                      Nouveau status
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModalStatus(false)}>
                      <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <input
                      type="text"
                      name="newStatut"
                      placeholder="Ajouter un statut"
                      value={newStatut}
                      onChange={handleInputChangeNewStatut}
                      className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                    />
                    {newStatutError ? (
                      <div className="text-red-600">{newStatutError}</div>
                    ) : null}
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setModalStatus(false);
                      }}>
                      Fermer
                    </button>
                    <button
                      className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={RegisterStatut}>
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {/* Modal niveau*/}
        {modalNiveau ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold text-orange-incubate">
                      Nouveau niveau
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModalNiveau(false)}>
                      <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <input
                      type="text"
                      name="newNiveau"
                      placeholder="Ajouter un niveau"
                      value={newNiveau}
                      onChange={handleInputChangeNewNiveau}
                      className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                    />
                    {newNiveauError ? (
                      <div className="text-red-600">{newNiveauError}</div>
                    ) : null}
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setModalNiveau(false);
                      }}>
                      Fermer
                    </button>
                    <button
                      className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={RegisterNiveau}>
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {/* Modal Priorite*/}
        {modalPriorite ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-xl font-semibold text-orange-incubate">
                      Nouveau priorite
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-red-900  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setModalPriorite(false)}>
                      <span className="bg-transparent text-red-900  h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <input
                      type="text"
                      name="newPriorite"
                      placeholder="Ajouter une priorite"
                      value={newPriorite}
                      onChange={handleInputChangeNewPriorite}
                      className="shadow appearance-none border rounded-lg w-full text-sm md:text-lg md:py-1 px-3 text-black leading-tight focus:outline-none focus:shadow-outline mb-2 sm:text-sm"
                    />
                    {newPrioriteError ? (
                      <div className="text-red-600">{newPrioriteError}</div>
                    ) : null}
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setModalPriorite(false);
                      }}>
                      Fermer
                    </button>
                    <button
                      className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={RegisterPriorite}>
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        ;
      </>
    );
  } else {
    return (
      <>
        <h1 className="text-center text-orange-incubate text-xl mt-1">
          Plan d'action
        </h1>
        {/* filtre */}
        <div className="flex justify-center items-start mt-3 w-2/3 mx-auto">
          <div>
            {/* FILTRE */}
            <div className=" rounded-md shadow-lg p-3 mr-5">
              <h2 className="font-bold text-center text-md mb-2">
                Choisissez vos filtres
              </h2>
              {allStatus.map((status) => (
                <div key={status.LIBELLE}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() =>
                        handleCheckboxChange("statut", status.LIBELLE)
                      }
                      checked={filtresSelectionnes.statut.includes(
                        status.LIBELLE
                      )}
                      className="mr-2"
                    />
                    <span className="text-red-900">Statut</span>{" "}
                    {status.LIBELLE}
                  </label>
                </div>
              ))}

              {allNiveau.map((niveau) => (
                <div key={niveau.LIBELLE}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() =>
                        handleCheckboxChange("niveau", niveau.LIBELLE)
                      }
                      checked={filtresSelectionnes.niveau.includes(
                        niveau.LIBELLE
                      )}
                      className="mr-2"
                    />
                    <span className="text-green-900">Niveau</span>{" "}
                    {niveau.LIBELLE}
                  </label>
                </div>
              ))}

              {allPriorite.map((priorite) => (
                <div key={priorite.LIBELLE}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() =>
                        handleCheckboxChange("priorite", priorite.LIBELLE)
                      }
                      checked={filtresSelectionnes.priorite.includes(
                        priorite.LIBELLE
                      )}
                      className="mr-2"
                    />
                    <span className="text-blue-900">Priorite</span>{" "}
                    {priorite.LIBELLE}
                  </label>
                </div>
              ))}

              <div>
                <label>
                  Filtrer par Critères :
                  <input
                    type="text"
                    value={filtresSelectionnes.libelle}
                    onChange={handleLibelleChange}
                    className="border border-black rounded-md ml-2"
                  />
                </label>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className="justify-center my-2 mx-2 text-sm py-2 px-5 flex hover:-translate-1 hover:scale-90 duration-300  hover:shadow-xl  rounded-lg bg-green-200"
                  onClick={appliquerFiltres}>
                  Appliquer les filtres
                </button>
              </div>
            </div>
          </div>
          {/* Ajoutez d'autres checkboxes pour d'autres valeurs de NIVEAU si nécessaire */}

          <table className="shadow-xl rounded-xl w-full ">
            <thead>
              <tr className="text-center border border-black">
                <th className="text-md text-bleu-incubate  bg-blue-50 border border-black">
                  Criteres
                </th>
                <th className="text-md text-bleu-incubate  bg-blue-50 border border-black">
                  Date
                </th>
                <th className="text-md text-orange-incubate  bg-green-50 border border-black">
                  Description
                </th>
                <th className="text-md text-orange-incubate  bg-orange-50 border border-black">
                  Status
                </th>
                <th className="text-md text-orange-incubate  bg-red-50 border border-black">
                  Niveau
                </th>
                <th className="text-md text-orange-incubate  bg-red-50 border border-black"></th>
              </tr>
            </thead>
            <tbody>
              {donneesFiltrees.length === 0 ? (
                <div className="flex justify-center">
                  {" "}
                  <p className="text center text-xl font-bold p-5">
                    Il n'y a pas de plan d'action.
                  </p>
                </div>
              ) : (
                <>
                  {donneesFiltrees.map((action, index) => (
                    <React.Fragment key={index}>
                      <tr
                        key={index}
                        className="items-center shadow-sm rounded-lg text-center border border-black ">
                        {/* criteres */}
                        <td className="text-orange-incubate text-md p-5 border border-black">
                          {action.LIBELLE}
                        </td>
                        {/* date */}
                        <td className="text-black text-md p-5 bg-blue-50 border border-black">
                          {action.DATE}
                          {action.DATE_MODIF !== null ? (
                            <p className="text-xs font-bold mt-2">
                              Modifié le : {action.DATE_MODIF}
                            </p>
                          ) : null}
                        </td>
                        {/* Description */}
                        <td className="text-left bg-green-50 p-5 border border-black">
                          {action.DESCRIPTION}
                        </td>
                        {/* Tendance */}
                        <td className="bg-orange-50 p-5 border border-black">
                          {action.LIBELLE_STATUS}{" "}
                        </td>
                        {/* Commentaire */}
                        <td className="bg-red-50 p-5 border border-black">
                          {action.LIBELLE_NIVEAU}{" "}
                        </td>
                        {/* Priorite */}
                        <td className="bg-red-50 p-5 border border-black">
                          {action.LIBELLE_PRIORITE}{" "}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
});

export default PlanAction;
