import React, { useEffect, useMemo, useState } from 'react'
import { useAsyncDebounce, useGlobalFilter, useSortBy, useTable, usePagination } from 'react-table';
import { BiPencil } from 'react-icons/bi';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

export default function Users() {
  //tableau des individus
  const [individus, setIndividus] = useState([]);

  //fonctionnement de la page  
  const navigate = useNavigate();
  const [loadingUser,setLoadingUser] = useState([true]);

  //Requete vers l'api pour récupérer les individus
  const fetchIndividus = async () => {
    //Options de requete
    const requestOptions = {
      method : 'GET',
      header : {
          'Content-Type' : 'application:json'
      }
    };

    //liste des utilisateurs
    fetch('/api/register/getAllUsers', requestOptions)
        .then(res => {
            return res.json();  
        }).then(data => {
          
            setIndividus(data);
            setLoadingUser(false)
        }).catch(error => {
            console.log(error);
        });
  }

  //Définition des colonnes du tableau
  const columns = useMemo(()=>([
    {
      Header : 'ID',
      accessor : 'ID_INDIVIDU'
    },
    {
      Header : 'Nom',
      accessor : 'NOM'
    },
    {
      Header : 'Prenom',
      accessor : 'PRENOM'
    },
    {
      Header : 'Mail',
      accessor : 'ADRESSE_MAIL'
    },
    {
      Header : 'Profil',
      accessor : 'LIBELLE_PROFIL'
    },
    {
      Header : 'Societe',
      accessor : 'LIBELLE_SOCIETE'
    }
  ]), []);

  //Création d'un use memo exploitable pour les individus
  const individusData = useMemo(() => [...individus], [individus]);

  //Fonction permetant de mettre en place les colonnes et d'ajouter celle de modification qui ne fais pas partie des individus
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns.slice(1),
      {
        id: "Modifier",
        Header: "",
        Cell: ({ row }) => (
          <button onClick={() => navigate(`/userEdit/${row.values.ID_INDIVIDU}`)}><BiPencil className='transition ease-in-out delay-150 hover:-translate-1 hover:scale-150 duration-300'/></button>
          ),
      },
    ]);
  };

  //Création d'une instance de tableau avec les colonnes, data, intinialState et différentes fonction que le tableau utilise
  const tableInstance = useTable(
    { 
      columns, 
      data : individusData,
      initialState : {pageIndex : 0, pageSize : 10},
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );
  
  //Définition des constantes pour le tableau provenant de l'instance de tableau 
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, preGlobalFilteredRows, state, setGlobalFilter, canPreviousPage, canNextPage, pageOptions, nextPage, previousPage, setPageSize, state: {pageIndex, pageSize} } = tableInstance;

  //Meme effet que componentDidMount c'est à dire charger les éléments (requetes individus) au chargement de la page
  useEffect(() => {
    fetchIndividus();
  }, []);

  //Fonction permetant de savoir si un numéro de ligne est pair ou non pour pouvoir modifier la couleur une ligne sur deux 
  const isEven = (idx) => idx % 2 === 0;

  //Fonction render affichant le tableau avec toutes les informations ci-dessus
    if (loadingUser){
        return <h1> </h1>
    }else{

  return (
    <div className='flex flex-col items-center justify-center h-full pt-32'>
      <h1 className="text-orange-incubate text-3xl">Liste des utilisateurs</h1>
      <div className='relative border shadow-md rounded-lg  mt-10'>
        <div className='flex flex-col md:flex-row items-center justify-center mt-2 mb-2'>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter}/>
          <button onClick={(event)=>{event.preventDefault(); navigate('/register');}} className='px-3 hover:bg-orange-incubate hover:text-white hover:border-orange-incubate transition ease-in-out delay-150 hover:-translate-1 hover:scale-110 duration-300 border rounded-full'>Ajouter un utilisateur</button>
        </div>
        <div className='overflow-x-scroll flex items-center justify-center'>
          <table className='text-left text-sm md:text-base' { ...getTableProps() }>
            <thead className='bg-orange-incubate text-xs text-white md:text-base'>
            {headerGroups.map((headerGroup, ) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                { headerGroup.headers.map((column) => (
                  <th scope='col' className='px-6 py-1' {...column.getHeaderProps(column.getSortByToggleProps())} >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""} 
                  </th>
              )) }
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              { page.map((row, idx)=>{
                prepareRow(row);

                return <tr {...row.getRowProps()} className={ "border-b hover:bg-orange-incubate hover:bg-opacity-50" + (isEven(idx) ? " bg-orange-incubate bg-opacity-10" : "")}>
                  {row.cells.map((cell, _idx)=> (
                    <td className='px-6 py-1' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr> 
              })}
            </tbody>
          </table>
        </div>
        <div className='pagination mt-2 flex flex-col md:flex-row items-center justify-center'>
          <div>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}><GrFormPrevious className=''/></button>
            <span>
                <strong>Page {pageIndex + 1} sur {pageOptions.length}</strong>
              </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}><GrFormNext className=''/></button>
          </div>
          <div className='md:ml-10'>
            { "Nombre d'individus par page " }
            <select value={pageSize} onChange={e => {setPageSize(Number(e.target.value))}}>
              {
                [10, 30, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
}

//Fonction permetant le tris par filtre dans le tableau (Recherche)
function GlobalFilter({ globalFilter, setGlobalFilter}){
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <div className='p-1 md:p-0'>
      <div className='relative mt-1'>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg className="invisible md:visible w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
        </div>
        <input name="tableSearch" type='text' value={value || ""} onChange={(e) => { setValue(e.target.value); onChange(e.target.value); }} className="ml-7 md:ml-0 border border-black text-sm rounded-full focus:border-orange-incubate block h-6 pl-10 w-9/12" placeholder={'Rechercher ...'} />
      </div>
    </div>
  );
}