import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";

class AdminRoute extends Component{
    constructor(props){
        super (props)
        //Variables utilisé pour la page
        this.state = {
            admin : false,
            loading : true
        }
    }

    //Fonctiond de chargement de données au chargement de la page
    componentDidMount(){
        const requestOptions = {
            method : 'GET',
            header : {
                'Content-Type' : 'application:json'
            }
        };
        fetch ('/api/isAdmin', requestOptions)
            .then(res => {
                if(res.status === 200 || res.status === 304){
                    this.setState({ admin : true, loading : false});
                }else{
                    this.setState({ admin : false, loading : false});
                }
            });
    }

    //Fonction de rendu de la page
    render(){
        if (this.state.loading){
            return <h1> </h1>
        }else{
            return this.state.admin ? <Outlet /> : <Navigate to="/notFoundPage"/>;
        }
    }
    
}

//Exports
export default AdminRoute;